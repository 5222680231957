import React, { Component } from "react";
import { FcSearch } from "react-icons/fc";
import Modal from "react-bootstrap/Modal";
import Banner from "./commons/banner";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import { Table, Button } from "react-bootstrap";
import officeBanner from "../images/officeBanner.jpg";
import officeImg from "../images/office.jpg";
import officeClock from "../images/office_clock.jpg";
import officeTeam from "../images/office_team.jpg";
import officeTravel from "../images/office_travel.jpg";
import officeFitness from "../images/office_fitness.jpg";
import officeSick from "../images/office_sick.jpg";
import officeComm from "../images/office_comm.jpg";
import processGetInTouch from "../images/process-getintouch.jpg";
import rdImg from "../images/research.svg";
import hrImg from "../images/seo.svg";
import progImg from "../images/programming.svg";
import purchaseImg from "../images/purchasing.svg";
import suppImg from "../images/supply-chain.svg";
import audImg from "../images/target-audience.svg";

class CareersPage extends Component {
  state = {
    bannerDetails: {
      bannerHeight: "300px",
      bgImage: officeBanner,
      title: "",
      subTitle: "",
      bgColor: "#7d7d7d",
      textColor: "#ffffff",
    },

    departments: [
      {
        id: 1,
        dep: "Research and Development",
        isPositionAvailable: true,
        positions: 4,
        img: rdImg,
        details: [
          {
            id: 1,
            position: "Electronics and Communication Engineering Intern",
            isIntern: true,
            noOfPos: 3,
          },
          {
            id: 2,
            position: "IOT Developer Intern",
            isIntern: true,
            noOfPos: 1,
          },
        ],
        desc:
          "R&D department plays the most important role I AVRN. The team is engaged in designing and developing new products, new services and new solutions for the industries and customers. This team is responsible for obtaining government approvals for different products as per standards. Implementation of prototype and field trials of products and services are also part of R&D process. This team is responsible for raw material selection and documentation before handing over the product to the production department.",
      },
      {
        id: 2,
        dep: "Production",
        img: suppImg,
        isPositionAvailable: false,
        positions: 0,
        desc:
          "Production Department is key to the progress of AVRN. It shoulders the responsibility of producing the product as per order. It maintains quality of raw material used, process, testing of product to ensure the quality. The team maximize yields, drive down costs, reduce waste and scrap, optimize processes through continuous improvements and ensure part and product traceability",
      },
      {
        id: 3,
        dep: "App and Web Devlopment",
        isPositionAvailable: true,
        img: progImg,
        positions: 4,
        details: [
          {
            id: 1,
            position: "Web Developer Intern",
            isIntern: true,
            noOfPos: 2,
          },
          {
            id: 2,
            position: "App Developer Intern",
            isIntern: true,
            noOfPos: 2,
          },
        ],
        desc:
          "Web and App Development team perform many functions in AVRN. It consist of a diverse group of people who all contribute their skills in multiple domains like Web and App design, front-end and backend design, UI/UX, content development, graphics and content development, database management,  programming, testing, deployment, support, security etc. The goal of the team is generally to bridge the gap between innovative and new digital products with unfamiliar audiences",
      },
      {
        id: 4,
        dep: "Human Resource",
        img: audImg,
        desc:
          "Finance and Accounts team at AVRN manages the preparation of balance sheets, financial statements, cash-flow reports, day-to-day record keeping and reporting, including all payroll, accounts payable and receivable. It has to manage and conduct all internal audits and controls, and tax and reporting functions. It makes sure that the AVRN is in line with regulations and is in good financial health.",
        isPositionAvailable: false,
        positions: 0,
      },
      {
        id: 5,
        dep: "Purchase",
        img: purchaseImg,
        desc:
          "Purchase department is responsible for the purchases of raw materials, tools, machinery, delivery trucks or even the office supplies needed for the different departments of AVRN. The team also takes care of vendor development, tenders and negotiations and follow just in time policy.",
        isPositionAvailable: false,
        positions: 0,
      },

      {
        id: 5,
        dep: "Marketing & Sales",
        img: hrImg,
        desc:
          "Sales department makes sale. The team is responsible for growing the business, operations and activities involved in promoting and selling goods or services. The team has to build awareness of a product or service. And even after a purchase, a sales team can help a business grow its repeat customers.",
        isPositionAvailable: false,
        positions: 0,
      },
    ],

    advantages: [
      {
        id: 1,
        text: "We care not only for you but your family - No nine to five jobs",
        img: officeClock,
      },
      {
        id: 2,
        text:
          "Unlimited Sick Leaves - We trust our employees, every employee is dedicated towards his/her project",
        img: officeSick,
      },
      {
        id: 3,
        text: "Every employee is encouraged to take tour & travel!",
        img: officeTravel,
      },
      {
        id: 4,
        text: "No office timings, team decides what works best for them.",
        img: officeTeam,
      },
      {
        id: 5,
        text:
          "Our people are great communicators, they work with independence and transperancy.",
        img: officeComm,
      },
      {
        id: 6,
        text:
          "We are concerned about your physical and mental health both. We encourage to gym and breaks to nourish your mental health.",
        img: officeFitness,
      },
    ],

    openAllPositionsModal: false,
    openDepModal: false,
    selectedDep: {},
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  handleAllPositionsClick = () => {
    const openAllPositionsModal = true;
    this.setState({ openAllPositionsModal });
  };

  handleDepartmentClick = (dep) => {
    const openDepModal = true;
    const selectedDep = { ...dep };
    this.setState({ openDepModal, selectedDep });
  };

  closeAllPositionModal = () => {
    const openAllPositionsModal = false;
    this.setState({ openAllPositionsModal });
  };

  closeDepModal = () => {
    const openDepModal = false;
    const selectedDep = {};
    this.setState({ openDepModal, selectedDep });
  };

  renderDepartmentDetails = () => {
    if (this.state.selectedDep.isPositionAvailable) {
      return this.state.selectedDep.details.map((pos) => (
        <tr>
          <td>{pos.position}</td>
          <td>{pos.noOfPos}</td>
          <td>
            <a
              className="btn btn-sm btn-outline-primary"
              href={pos.isIntern ? "/internForm" : "/career-form"}
            >
              Apply
            </a>
          </td>
        </tr>
      ));
    } else {
      return (
        <div style={{ width: "100%" }}>
          <tr>
            <td colSpan="2">
              No position available currently, but you can drop your resume
              here...
            </td>
          </tr>
          <tr>
            <td className="text-center">
              <a href="/internForm" className="btn btn-sm btn-outline-primary">
                Intern
              </a>
            </td>
            <td className="text-center">
              <a href="/career-form" className="btn btn-sm btn-outline-primary">
                Full Time Employee
              </a>
            </td>
          </tr>
        </div>
      );
    }
  };

  renderAllDepartments = () => {
    return this.state.departments.map((dep) =>
      dep.isPositionAvailable
        ? dep.details.map((pos) => (
            <tr>
              <td>{pos.position}</td>
              <td>{pos.noOfPos}</td>
              <td>
                <a
                  className="btn btn-sm btn-outline-primary"
                  href={pos.isIntern ? "/internForm" : "/career-form"}
                >
                  Apply
                </a>
              </td>
            </tr>
          ))
        : ""
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid main-container">
          <h1 style={{ fontSize: "72px" }}>
            <span style={{ color: "#7d7d7d" }}>Lets Build</span>
            <br />
            <span style={{ color: "#f5a800" }}>a Future Together...</span>
          </h1>
          <br />
          <p
            style={{
              fontSize: "22px",
              fontWeight: "lighter",
              color: "#7d7d7d",
            }}
          >
            What would you like to do..
          </p>
          <div className="searchbox-container">
            <form onSubmit={this.handleSearchSubmit} className="row">
              <div className="form-group col-md-5">
                <input
                  type="text"
                  className="form-control"
                  style={{ height: "50px" }}
                  placeholder="Ex. Android Developer, Manager, HR"
                />
              </div>
              <div className="form-group col-6">
                <button
                  className="btn"
                  style={{
                    height: "50px",
                    background: "#f5a800",
                    color: "#000000",
                    fontSize: "20px",
                  }}
                >
                  Search
                </button>
              </div>
            </form>
          </div>

          <div className="bottom-margin">
            <p className="positions">
              Let's view all the open{" "}
              <span
                className="positions-text"
                onClick={this.handleAllPositionsClick}
              >
                positions..
              </span>
            </p>
          </div>
        </div>

        <Banner details={this.state.bannerDetails} />

        <div className="blocks">
          <div className="block block--left">
            <div className="usp-content">
              <p>We don't work Nine to Five, We don't have office timings.</p>
              <p>
                Reputation goes with your work and every work is important in
                AVRN.
              </p>
              <p>
                We believe a mentor lies in each one of us and it makes a person
                more responsible for his/her work and team.
              </p>
            </div>
          </div>
          <div className="block block--right"></div>
        </div>

        <div className="departments-container-one">
          <h1 className="text-center" style={{ fontSize: "32px" }}>
            {" "}
            <span style={{ color: "#7d7d7d" }}>Our</span>{" "}
            <span style={{ color: "#f5a800", fontWeight: "lighter" }}>
              Department
            </span>
          </h1>
          <br></br>
          <Row>
            {this.state.departments.map((dep) => (
              <Col sm={4} key={dep.id}>
                <Card
                  className="departments-cards"
                  onClick={() => this.handleDepartmentClick(dep)}
                >
                  <Card.Img
                    variant="top"
                    src={dep.img}
                    className="dep-images"
                  />
                  <Card.Body className="departments-cards-body">
                    <h4 className="text-center">{dep.dep}</h4>
                    <p class="text-justify">{dep.desc}</p>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>

        <div className="container-fluid advantages-container">
          <h1
            className="text-center bottom-margin"
            style={{ fontSize: "32px", color: "#7d7d7d" }}
          >
            We Care About Our <span style={{ color: "#f5a800" }}>People</span>
          </h1>
          <Row>
            {this.state.advantages.map((adv) => (
              <Col md={4} className="advantages-col">
                <Card className="advantages-card">
                  <Card.Img variant="top" src={adv.img} height="250px" />
                  <Card.Body className="advantages-card-body">
                    <Card.Text style={{ fontSize: "16px" }}>
                      {adv.text}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>

        <div className="container-fluid quotes-container">
          <h1 className="text-center main-quote-line-1">
            We Try, We May Fail..
          </h1>
          {/* <h1 className="text-center main-quote-line-2">
            We Try Again, We May Fail Again..
          </h1> */}
          <h1 className="text-center main-quote-line-3">
            We Try, Till We Succeed..
          </h1>
          <h1 className="text-center second-quote-line-1">
            At AVRN we are not afraid of failure,
            <br /> we are afraid of quitting..
          </h1>
          <p
            className="text-center"
            style={{ fontSize: "24px", marginTop: "2%" }}
          >
            We have a team from different cultures
            <br />
            with
            <br /> Different mindsets
            <br />
            but
            <br />
            Unified in problem solving <br />
            and
            <br /> Believe in learning and growing.
          </p>
        </div>

        <div className="process-container">
          <h3 className="text-center">Recruitment Process</h3>
          <div className="container-fluid process-container-text">
            <p>We usually take two to three weeks from start to finish</p>
            <ol type="1">
              <div className="p-blocks">
                <div className="p-block p-block--left">
                  <div className="">
                    <li>
                      <p className="process-header">Getting in touch with us</p>
                      <ul>
                        <li>
                          The easiest way to reach us is by sending us your
                          resume at apply@avrn.in or just go and upload your
                          credentials on our website{" "}
                          <a href="www.avrn.in/careers">www.avrn.in/careers</a>.
                        </li>
                        <li>
                          We take resumes seriously. One of us will reach out to
                          you, after we have had a chance to understand what
                          you’re looking for.
                        </li>
                        <li>
                          We would love it if you could include links to all of
                          your work that is in the public domain that you think
                          would help us understand you better. Apps, side
                          projects, open source contributions, blogs, anything
                          related to the job role you are applying for — please
                          link all of them in your resume.
                        </li>
                      </ul>
                    </li>
                  </div>
                </div>
                <div className="p-block p-block--right getInTouchBg"></div>
              </div>

              <div className="l-blocks">
                <div className="l-block l-block--left callBg"></div>
                <div className="l-block l-block--right">
                  <li>
                    <p className="process-header">A Quick Phone Call</p>
                    <p>
                      One of us will set up a quick phone call with you. The
                      conversation will last anywhere from 20 minutes to an
                      hour, depending on the role. <br />
                      We have two basic objectives for this call:
                    </p>
                    <ul>
                      <li>
                        First, if you have any questions about the work that we
                        do, or around the role that you are applying for, we
                        will do our utmost to answer them. If we can’t, we may
                        schedule a follow-up call with someone in AVRN who can.
                      </li>
                      <li>
                        Second, we would like to understand your experience
                        better and see if there is a match between your skills
                        and what we are looking for. Let’s face it, a resume
                        does not always do justice to a person’s rich
                        experience. Hence, the call!
                      </li>
                    </ul>
                  </li>
                </div>
              </div>

              <div className="p-blocks">
                <div className="p-block p-block--left">
                  <li>
                    <p className="process-header">Do Some Assignment</p>
                    <p>Lets not talk, solve some real world problems</p>
                    <p>
                      The only way to evaluate an applicant is through his
                      skills. Since a real world problem would take far too long
                      to solve, we have instead consciously selected a very
                      simple, high school level command line based problem.
                    </p>
                    <p>For Technical Applicants:</p>
                    <ul>
                      <li>
                        You can use only designated language to solve the
                        problem. We emphasise writing clean, well designed,
                        decoupled code that follows clear conventions, has fine
                        grained commits and has solid test coverage. Aside from
                        a unit testing library, the problem needs no external
                        libraries or frameworks.
                      </li>
                      <li>
                        Typically, for someone comfortable with the language,
                        git and a unit testing library the solution will take no
                        more than 2 to 4 hours. You will have 24 hours to
                        complete this problem, and you can do so from the
                        comfort of your home.
                      </li>
                    </ul>
                    <p>For Other applicants:</p>
                    <ul>
                      <li>
                        Your skills will be judged in the same pattern mentioned
                        above on the problems faced in your domains.
                      </li>
                    </ul>
                  </li>
                </div>
                <div className="p-block p-block--right assignmentBg"></div>
              </div>

              <div className="l-blocks">
                <div className="l-block l-block--left reviewBg"></div>
                <div className="l-block l-block--right">
                  <li>
                    <p className="process-header">Assignment Review</p>
                    <ul>
                      <li>
                        Once you submit the solution , we have it evaluated by
                        our team that validates basic hygiene factors like
                        output, and if that clears, by a senior AVRN expert for
                        quality and design.
                      </li>
                      <li>
                        The problem statement has a set of rules. Please pay
                        careful attention to them. Part of what we evaluate in
                        this stage is the ability to grasp written requirements.
                        You are, of course, always welcome to come back to us
                        with questions and clarifications.
                      </li>
                      <li>
                        Our responsiveness on this stage varies from one to
                        three days.
                      </li>
                      <li>
                        After reviewing your solution, if the results are
                        positive, we will invite you to our office for further
                        rounds of discussion. You can choose the day that you
                        would like to come in based on your convenience. Once
                        you come into our office, we ensure that all the rounds
                        are completed on the same day.
                      </li>
                    </ul>
                  </li>
                </div>
              </div>

              <div className="p-blocks">
                <div className="p-block p-block--left">
                  <li>
                    <p className="process-header">Technical Interviews</p>
                    <p>
                      There will be between one and three rounds of technical
                      interviews.
                    </p>
                    <ul>
                      <li>
                        In terms of focus areas of these interviews, you will
                        find a strong emphasis on design, code quality, level of
                        comfort with all layers of the stack and ability to
                        build working software from ideation to production. We
                        will also delve into prior projects, key design and
                        execution decisions made there and the rationale behind
                        them.
                      </li>
                      <li>
                        A thorough understand of the tools you use most often,
                        including programming languages, editors, shells and
                        systems will also be expected.
                      </li>
                    </ul>
                  </li>
                </div>
                <div className="p-block p-block--right techInterviewBg"></div>
              </div>

              <div className="l-blocks">
                <div className="l-block l-block--left raiserBg"></div>
                <div className="l-block l-block--right">
                  <li>
                    <p className="process-header">The Raiser</p>
                    <p>
                      We are very choosy about whom we make part of our family.
                      That is why, in the penultimate round of discussions,
                      every candidate must speak with a member of the Board of
                      Directors. The conversation would revolve around your
                      aspirations and expectations from AVRN, as well as our
                      expectations from you.
                      <br />
                      Post the Raiser, we will have an offer related
                      conversation with you. In most cases, we will make an
                      offer to you, on the very same day!
                    </p>
                  </li>
                </div>
              </div>
            </ol>
            <Row>
              <Col md={3}></Col>
              <Col md={6}>
                <p style={{ fontSize: "18px", textAlign: "center" }}>
                  We hope this article has clarified your questions regarding
                  the recruitment process as a whole. Throughout the process,
                  you would have someone from our team, most likely a recruiter,
                  stay in touch with you.
                  <br />
                  So what are you waiting for? Apply to us on apply@avrn.in.
                </p>
              </Col>
              <Col md={3}></Col>
            </Row>
          </div>
        </div>

        <div
          style={{
            fontSize: "36px",
            textAlign: "center",
            color: "#f5a800",
            cursor: "pointer",
          }}
        >
          <a
            href="/career-form"
            style={{
              color: "#f5a800",
              fontWeight: "bold",
            }}
          >
            Click here so we will know you better.
          </a>
        </div>

        {/* <div className="departments-container">
          <h3 className="text-center">Choose A Department</h3>
          <br></br>
          <Row>
            {this.state.departments.map((dep) => (
              <Col sm={3} key={dep.id}>
                <Card
                  className="departments-cards"
                  
                >
                  <Card.Body className="departments-cards-body">
                    <h4 className="text-center">{dep.dep}</h4>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div> */}

        <Modal
          show={this.state.openAllPositionsModal}
          onHide={this.closeAllPositionModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              All Open Positions
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="career-modal-body">
            <Table bordered hover striped>
              {this.renderAllDepartments()}
            </Table>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.openDepModal}
          onHide={this.closeDepModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {this.state.selectedDep.dep}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="career-modal-body">
            <Table bordered hover striped>
              {this.renderDepartmentDetails()}
            </Table>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default CareersPage;

import React, { Component } from "react";
import MainCarousel from "./carousel";
import ChooseUs from "./chooseus";
import KeyPoints from "./keypoints";
import OurProducts from "./ourproducts";
import OurServices from "./services";
import ContactUS from "./contactus";
import TechUsed from "./tech_used";

class Home extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <MainCarousel />
        <ChooseUs />
        <OurServices />
        {/* <OurProducts /> */}
        <KeyPoints />
        <TechUsed />
        <ContactUS />
      </React.Fragment>
    );
  }
}

export default Home;

import React, { Component } from "react";
import { Col } from "react-bootstrap";

// import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

// import YearPicker from "react-year-picker";
import axios from "axios";
import "./css/RecruitmentForm.css";
import processGetInTouch from "../images/process-getintouch.jpg";

//import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ImageUploaderCropper from "./commons/imageUploaderCropper";

class RecruitmentForm extends Component {
  state = {
    fields: {
      title: "",
      first_name: "",
      last_name: "",
      father_name: "",
      email: "",
      mobile: "",
      gender: "",
      department: "",
      post: "",
      applying_for: "",
      dob: "",
      age: "",
      curr_add_line_1: "",
      curr_add_line_2: "",
      curr_add_line_3: "",
      curr_add_state: "",
      curr_add_city: "",
      curr_add_zip: "",
      perm_add_line_1: "",
      perm_add_line_2: "",
      perm_add_line_3: "",
      perm_add_state: "",
      perm_add_city: "",
      perm_add_zip: "",
      isPhd: false,
      phd_name: "",
      phd_subject: "",
      phd_uni: "",
      phd_insti: "",
      phd_start: "",
      phd_end: "",
      phd_marks: "",
      phd_mode: "",
      isPg: false,
      pg_name: "",
      pg_subject: "",
      pg_uni: "",
      pg_insti: "",
      pg_start: "",
      pg_end: "",
      pg_marks: "",
      pg_mode: "",
      isGrad: false,
      grad_name: "",
      grad_subject: "",
      grad_uni: "",
      grad_insti: "",
      grad_start: "",
      grad_end: "",
      grad_marks: "",
      grad_mode: "",
      isDiploma: false,
      diploma_name: "",
      diploma_subject: "",
      diploma_uni: "",
      diploma_insti: "",
      diploma_start: "",
      diploma_end: "",
      diploma_marks: "",
      diploma_mode: "",
      isTen: false,
      ten_name: "",
      ten_subject: "",
      ten_uni: "",
      ten_insti: "",
      ten_start: "",
      ten_end: "",
      ten_marks: "",
      ten_mode: "",
      isTwelve: false,
      twelve_name: "",
      twelve_subject: "",
      twelve_uni: "",
      twelve_insti: "",
      twelve_start: "",
      twelve_end: "",
      twelve_marks: "",
      twelve_mode: "",
      total_exp_years: "",
      total_exp_months: "",
      curr_org_name: "",
      curr_org_desg: "",
      curr_org_responsiblities: "",
      current_org_reporting_to: "",
      curr_org_from: "",
      curr_org_to: "",
      localAddr: "",
      permAddr: "",
      profile: "",
      refrence: "",
      ref_explain: "",
    },
    positionDetails: {
      techSkills: "",
      mgmtSkills: "",
      kitchSkills: "",
      driverLicense: "",
    },
    previousOrgs: [
      {
        orgName: "",
        desg: "",
        responsiblities: "",
        serviceFrom: "",
        serviceTo: "",
        reportedTo: "",
      },
    ],
    eduOptions: ["PhD", "P.G.", "U.G.", "Diploma", "12", "10", "Certifciation"],
    qualifications: [
      {
        edu: "",
        name: "",
        subject: "",
        uni: "",
        insti: "",
        start: "",
        end: "",
        marks: "",
        mode: "",
      },
    ],
    errors: {},
    position: "select",
    vehicle: "",
    experience: "select",
    profileImg: "",
    cv: "",
    selectedDep: null,
    is_dep_selected: false,
    is_post_others: false,
    departments: [
      {
        id: 1,
        dep: "Research and Development",
        isPositionAvailable: true,
        positions: 4,
        img: processGetInTouch,
        details: [
          {
            id: 1,
            position: "Electronics and Communication Engineering Intern",
            isIntern: true,
            noOfPos: 3,
          },
          {
            id: 2,
            position: "IOT Developer Intern",
            isIntern: true,
            noOfPos: 1,
          },
        ],
      },
      {
        id: 2,
        dep: "Production",
        img: processGetInTouch,
        isPositionAvailable: false,
        positions: 0,
      },
      {
        id: 3,
        dep: "App and Web Devlopment",
        isPositionAvailable: true,
        img: processGetInTouch,
        positions: 4,
        details: [
          {
            id: 1,
            position: "Web Developer Intern",
            isIntern: true,
            noOfPos: 2,
          },
          {
            id: 2,
            position: "App Developer Intern",
            isIntern: true,
            noOfPos: 2,
          },
        ],
      },
      {
        id: 4,
        dep: "Purchase",
        img: processGetInTouch,
        isPositionAvailable: false,
        positions: 0,
      },
      {
        id: 5,
        dep: "Human Resource",
        img: processGetInTouch,
        isPositionAvailable: false,
        positions: 0,
      },
      {
        id: 6,
        dep: "Marketing & Sales",
        img: processGetInTouch,
        isPositionAvailable: false,
        positions: 0,
      },
    ],
    src: null,
    crop: {
      unit: "%",
      width: 30,
      aspect: 1 / 1,
    },
    croppedImageUrl: null,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.fields.isSameAddr !== this.state.fields.isSameAddr &&
      this.state.fields.addrLine1 !== prevState.fields.permAddrLine1 &&
      this.state.fields.addrLine2 !== prevState.fields.permAddrLine2 &&
      this.state.fields.state !== prevState.fields.permState &&
      this.state.fields.city !== prevState.fields.permCity &&
      this.state.fields.zip !== prevState.fields.permZip
    ) {
      if (this.state.fields.isSameAddr) {
        const fields = { ...this.state.fields };
        const line1 = fields.addrLine1;
        const line2 = fields.addrLine2;
        const state = fields.state;
        const city = fields.city;
        const zip = fields.zip;

        this.setState({
          fields: {
            ...fields,
            permAddrLine1: line1,
            permAddrLine2: line2,
            permState: state,
            permCity: city,
            permZip: zip,
          },
        });
      }
    } else if (
      prevState.fields.isSameAddr !== this.state.fields.isSameAddr &&
      !this.state.fields.isSameAddr
    ) {
      const fields = { ...this.state.fields };
      this.setState({
        fields: {
          ...fields,
          permAddrLine1: "",
          permAddrLine2: "",
          permState: "",
          permCity: "",
          permZip: "",
        },
      });
    }

    if (
      this.state.profileImg !== prevState.profileImg &&
      this.state.profileImg
    ) {
      // document.querySelector(".img-holder button").style.width = "auto";
      // document.querySelector(".img-holder button").style.height = "auto";
    }

    if (this.state.fields.localAddr !== prevState.fields.localAddr) {
      console.log(this.state.fields.localAddr);
    }
  }

  imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ profileImg: reader.result });
      }
    };
    this.setState({
      fields: { ...this.state.fields, profile: e.target.files[0] },
    });
    //this.setState({ src: reader.result });
    reader.readAsDataURL(e.target.files[0]);
  };

  cvHandler = (event) => {
    // Update the state
    this.setState({ cv: event.target.files[0] });
  };

  removeFile = () => {
    this.setState({ profileImg: null });
  };

  handleChange = (e) => {
    let fields = { ...this.state.fields };
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      this.setState({ fields: { ...fields, [name]: checked } });
    } else if (name === "department") {
      let departments = this.state.departments;
      let selectedDep = {};
      let is_dep_selected = true;
      for (var dep of departments) {
        if (dep.dep === value) {
          selectedDep = dep;
        }
      }
      this.setState({
        fields: {
          ...fields,
          [name]: value,
        },
        selectedDep: selectedDep,
        is_dep_selected: is_dep_selected,
      });
    } else if (name === "post") {
      let is_post_others = false;
      if (value === "Others") {
        is_post_others = true;
      }
      let temp = value.split(" ");
      if (temp[temp.length - 1] === "Intern") {
        this.props.history.push("internForm");
      }
      this.setState({
        fields: {
          ...fields,
          [name]: value,
        },
        is_post_others: is_post_others,
      });
    } else {
      this.setState({
        fields: {
          ...fields,
          [name]: value,
        },
      });
    }
  };

  positionHandle = (e) => {
    const { name, value } = e.target;
    const pDetails = { ...this.state.positionDetails };
    this.setState({ positionDetails: { ...pDetails, [name]: value } });
  };

  handleAddr = () => {
    const dob = new Date(this.state.fields.dob);
    const birthYear = dob.getFullYear();
    const birthMonth = dob.getMonth();
    const birthDate = dob.getDate();
    const currentDay = new Date();
    const currentYear = currentDay.getFullYear();
    const currentMonth = currentDay.getMonth();
    const currentDate = currentDay.getDate();

    const currF = { ...this.state.fields };
    let age;

    if (
      currentMonth > birthMonth ||
      (currentMonth === birthMonth && currentDate >= birthDate)
    ) {
      age = currentYear - birthYear;
    } else {
      age = currentYear - birthYear - 1;
    }

    // Handle adress concatenation
    const fullLocalAdrr =
      currF.addrLine1 +
      "\n" +
      currF.addrLine2 +
      "\n" +
      currF.city +
      "-" +
      currF.zip +
      "\n" +
      currF.state;

    const fullPermAdrr =
      currF.permAddrLine1 +
      "\n" +
      currF.permAddrLine2 +
      "\n" +
      currF.permCity +
      "-" +
      currF.permZip +
      "\n" +
      currF.permState;

    const langs = this.state.fields.lang.split(",").map((word) => word.trim());

    const techSkills = this.state.positionDetails.techSkills
      .split(",")
      .map((ele) => ele.trim());
    const mgmtSkills = this.state.positionDetails.mgmtSkills
      .split(",")
      .map((ele) => ele.trim());
    const kitchSkills = this.state.positionDetails.kitchSkills
      .split(",")
      .map((ele) => ele.trim());

    this.setState({
      fields: {
        ...currF,
        localAddr: fullLocalAdrr,
        permAddr: fullPermAdrr,
        age: age,
        lang: langs,
      },
      positionDetails: {
        ...this.state.positionDetails,
        techSkills: techSkills,
        mgmtSkills: mgmtSkills,
        kitchSkills: kitchSkills,
      },
    });
    return;
  };

  submitCareerForm = (e) => {
    e.preventDefault();

    if (this.validateForm()) {
      this.handleAddr();

      setTimeout(() => {
        let formData = new FormData();

        const config = {
          headers: { "content-type": "multipart/form-data" },
        };

        const formUrl = "";

        formData.append("cv", this.state.cv);
        formData.append("img", this.state.fields.profile);
        formData.append("firstname", this.state.fields.fname);
        formData.append("lastname", this.state.fields.lname);
        formData.append("email", this.state.fields.email);
        formData.append("mobile", this.state.fields.mobileno);
        formData.append("altMobile", this.state.fields.altmobile);
        formData.append("gender", this.state.fields.gender);
        formData.append("age", this.state.fields.age);
        formData.append("localCity", this.state.fields.city);
        formData.append("localState", this.state.fields.state);
        formData.append("permCity", this.state.fields.permCity);
        formData.append("permState", this.state.fields.permState);
        formData.append("languages", this.state.fields.lang);
        formData.append("localAddress", this.state.fields.localAddr);
        formData.append("permanentAddress", this.state.fields.permAddr);
        formData.append("education", this.state.fields.education);
        if (this.state.fields.ugDegree) {
          formData.append("ugDegree", this.state.fields.ugDegree);
          formData.append("pgDegree", "");
        }
        if (this.state.fields.pgDegree) {
          formData.append("pgDegree", this.state.fields.pgDegree);
          formData.append("ugDegree", "");
        }
        formData.append("experience", this.state.fields.experience);
        formData.append("appliedPos", this.state.fields.position);
        formData.append("infoAboutUs", this.state.fields.infoAboutUs);
        if (this.state.positionDetails.techSkills) {
          formData.append("techSkills", this.state.positionDetails.techSkills);
          formData.append("mgmtSkills", "");
          formData.append("kitchenSkills", "");
          formData.append("driverLicense", "");
        }

        if (this.state.positionDetails.mgmtSkills) {
          formData.append("techSkills", "");
          formData.append("mgmtSkills", this.state.positionDetails.mgmtSkills);
          formData.append("kitchenSkills", "");
          formData.append("driverLicense", "");
        }

        if (this.state.positionDetails.kitchSkills) {
          formData.append("techSkills", "");
          formData.append("mgmtSkills", "");
          formData.append(
            "kitchenSkills",
            this.state.positionDetails.kitchSkills
          );
          formData.append("driverLicense", "");
        }

        if (this.state.positionDetails.driverLicense) {
          formData.append("techSkills", "");
          formData.append("mgmtSkills", "");
          formData.append("kitchenSkills", "");
          formData.append(
            "driverLicense",
            this.state.positionDetails.driverLicense
          );
        }

        axios
          .post(formUrl, formData, config)
          .then((response) => {
            if (response) window.history.go(-1);
          })
          .catch((error) => {
            console.error(error);
          });
      }, 1000);
    } else {
      alert("Please fill the form as specified");
    }
  };

  validateForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["fname"]) {
      formIsValid = false;
      errors["fname"] = "Please enter your First Name";
    }
    if (typeof fields["fname"] !== "undefined") {
      if (!fields["fname"].match(/^[a-zA-Z]*$/)) {
        formIsValid = false;
        errors["fname"] = "Please enter only Alphabets";
      }
    }

    if (!fields["lname"]) {
      formIsValid = false;
      errors["lname"] = "Please enter your Last Name";
    }
    if (typeof fields["lname"] !== "undefined") {
      if (!fields["lname"].match(/^[a-zA-Z]*$/)) {
        formIsValid = false;
        errors["lname"] = "Please enter only Alphabets";
      }
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Please enter your Email ID";
    }
    if (typeof fields["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid Email-ID.";
      }
    }

    if (!fields["mobileno"] || !fields["altmobile"]) {
      formIsValid = false;
      errors["mobileno"] = "*Please enter your mobile no.";
    }

    if (
      fields["mobileno"] &&
      !/^((\+)?(\d{2}[-]))?(\d{10}){1}?$/.test(fields["mobileno"])
    ) {
      formIsValid = false;
      errors["mobileno"] = "Please enter a valid mobile number";
    }
    if (
      fields["altmobile"] &&
      !/^((\+)?(\d{2}[-]))?(\d{10}){1}?$/.test(fields["altmobile"])
    ) {
      formIsValid = false;
      errors["mobileno"] = "Please enter a valid mobile number";
    }

    if (!fields["gender"]) {
      formIsValid = false;
      errors["gender"] = "Please choose a gender";
    }

    if (!fields["dob"]) {
      formIsValid = false;
      errors["dob"] = "Please enter your date of birth";
    }

    if (!fields["lang"]) {
      formIsValid = false;
      errors["lang"] = "Please fill out the languages' field";
    }

    if (
      !fields["addrLine1"] ||
      !fields["addrLine2"] ||
      !fields["permAddrLine1"] ||
      !fields["permAddrLine2"]
    ) {
      formIsValid = false;
      errors["addrLine"] = "Please enter a valid address line";
    }

    if (!fields["state"] || !fields["permState"]) {
      formIsValid = false;
      errors["state"] = "Please choose a State";
    }

    if (!fields["city"] || !fields["permCity"]) {
      formIsValid = false;
      errors["city"] = "Please choose a City";
    }

    if (fields["permCity"] && !/^[a-zA-Z]+$/.test(fields["permCity"])) {
      formIsValid = false;
      errors["city"] = "Please enter a valid city";
    }

    if (!fields["zip"] || !fields["permZip"]) {
      formIsValid = false;
      errors["zip"] = "Enter a valid Zip Code";
    }

    if (fields["zip"] && !/^[0-9]+$/.test(fields["permZip"])) {
      formIsValid = false;
      errors["zip"] = "Please enter a valid zip code";
    }

    if (!fields["education"]) {
      formIsValid = false;
      errors["education"] = "Please choose an option";
    }

    if (!fields["experience"]) {
      formIsValid = false;
      errors["experience"] = "Please enter your experience";
    }

    if (!fields["position"]) {
      formIsValid = false;
      errors["position"] = "Please choose position you wish to apply for";
    }
    if (!fields["infoAboutUs"]) {
      formIsValid = false;
      errors["info"] = "Please enter a valid source";
    }
    if (
      this.state.fields.position === "delivery" &&
      (!this.state.positionDetails["driverLicense"] ||
        /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/.test(
          this.state.positionDetails["driverLicense"]
        ))
    ) {
      formIsValid = false;
      errors["driver"] = "Please enter a valid driver license";
    }

    this.setState({
      errors: errors,
    });

    return formIsValid;
  };

  showCorrectFields = () => {
    if (this.state.fields.education === "bachelors") {
      return (
        <div className="row form-group">
          <div
            className="col-md-4"
            style={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <Form.Label>UG Degree</Form.Label>
          </div>
          <div className="col-md-8">
            <select
              name="ugDegree"
              className="form-control"
              onChange={this.handleChange}
              style={{ margin: "0" }}
              value={this.state.fields.ugDegree}
            >
              <option value="" selected disabled>
                Select UG Degree
              </option>
              <option value="arts">Bachelor of Arts</option>
              <option value="commerce">Bachelor of Commerce</option>
              <option value="science">Bachelor of Science</option>
              <option value="engg">Bachelor of Engg/ Bachelor of Tech.</option>
              <option value="administration">
                Bachelor of Business Administration
              </option>
              <option value="otherUG">Other UG Course</option>
            </select>
            <div className="errorMsg">{this.state.errors.degree}</div>
          </div>
        </div>
      );
    } else if (this.state.fields.education === "masters") {
      return (
        <div className="row form-group">
          <div
            className="col-md-4"
            style={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <Form.Label>PG Degree</Form.Label>
          </div>
          <div className="col-md-8">
            <select
              name="pgDegree"
              className="form-control"
              onChange={this.handleChange}
              style={{ margin: "0" }}
              value={this.state.fields.pgDegree}
            >
              <option value="" selected disabled>
                Select PG Degree
              </option>
              <option value="arts">Masters of Arts</option>
              <option value="science">Masters of Science</option>
              <option value="engg">Masters of Engineering/Technology</option>
              <option value="administration">
                Masters of Business Administration
              </option>
              <option value="otherUG">Other PG Course</option>
            </select>
            <div className="errorMsg">{this.state.errors.education}</div>
          </div>
        </div>
      );
    }
  };

  showRoleForm = () => {
    if (this.state.fields.position !== "") {
      return (
        <div>
          <Form.Group className="row">
            <div
              className="col-md-4"
              style={{
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <Form.Label>Skills*:</Form.Label>
            </div>
            <div className="col-md-8">
              <textarea
                name="skills"
                id="skills"
                placeholder="Mention Your Skills"
                rows="5"
                className="form-control"
                value={this.state.skills}
                onChange={this.handleChange}
                required
              ></textarea>
            </div>
          </Form.Group>

          <Form.Group className="row">
            <div
              className="col-md-4"
              style={{
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <Form.Label>
                Certifications(Courses qualified, Ex: NPTEL, RedHat, UpGrad):
              </Form.Label>
            </div>
            <div className="col-md-8">
              <textarea
                name="certifications"
                id="certifications"
                placeholder="Mention Your Certifications"
                rows="5"
                className="form-control"
                value={this.state.fields.certifications}
                onChange={this.handleChange}
              ></textarea>
            </div>
          </Form.Group>

          <Form.Group className="row">
            <div
              className="col-md-4"
              style={{
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <Form.Label>Project Links:</Form.Label>
            </div>
            <div className="col-md-8">
              <textarea
                name="projectLinks"
                id="projectLinks"
                placeholder="Github or Google Drive Links of your project"
                rows="5"
                className="form-control"
                value={this.state.fields.projectLinks}
                onChange={this.handleChange}
              ></textarea>
            </div>
          </Form.Group>

          <Form.Group className="row">
            <div
              className="col-md-4"
              style={{
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <Form.Label>Why Do You Want to Join AVRN Labs?*</Form.Label>
            </div>
            <div className="col-md-8">
              <textarea
                name="whyDoYouWantToJoin"
                id="whyDoYouWantToJoin"
                placeholder="Answer in brief(200 words)"
                rows="5"
                className="form-control"
                value={this.state.fields.whyDoYouWantToJoin}
                onChange={this.handleChange}
                required
              ></textarea>
            </div>
          </Form.Group>

          <Form.Group className="row">
            <div
              className="col-md-4"
              style={{
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <Form.Label>Previous Experiecne(If Any)</Form.Label>
            </div>
            <div className="col-md-8">
              <textarea
                name="exp"
                id="exp"
                placeholder="Mention previous internships"
                rows="5"
                className="form-control"
                value={this.state.fields.exp}
                onChange={this.handleChange}
              ></textarea>
            </div>
          </Form.Group>
        </div>
      );
    } else {
      return <div> </div>;
    }
  };

  renderPostOptions = () => {
    if (this.state.selectedDep) {
      if (this.state.selectedDep.isPositionAvailable) {
        let posts = this.state.selectedDep.details;
        posts = [
          ...posts,
          {
            id: 0,
            position: "Others",
            isIntern: false,
            noOfPos: 3,
          },
        ];
        // return posts.map((post) => (
        //   <option value={post.position} key={post.id}>
        //     {post.position}
        //   </option>
        // ));
        return (
          <div className="col-md-4">
            <label>Post*:</label>
            <select
              className="form-control"
              name="post"
              value={this.state.fields.post}
              onChange={this.handleChange}
              required
            >
              <option>Choose One..</option>
              {posts.map((post) => (
                <option value={post.position} key={post.id}>
                  {post.position}
                </option>
              ))}
            </select>
          </div>
        );
      } else {
        return (
          <div className="col-md-4">
            <label>Post*:</label>
            <select
              className="form-control"
              name="post"
              value={this.state.fields.post}
              onChange={this.handleChange}
              required
            >
              <option value="0">Just Dropping By</option>
            </select>
          </div>
        );
      }
    } else {
      return (
        <div className="col-md-4">
          <label>Post*:</label>
          <select
            className="form-control"
            name="post"
            value={this.state.fields.post}
            onChange={this.handleChange}
            required
            disabled
          >
            <option value="0">Choose One..</option>
          </select>
        </div>
      );
    }
  };

  renderOthersPost = () => {
    if (this.state.is_post_others === true) {
      return (
        <div className="col-md-4">
          <label>Applying for:</label>
          <input
            type="text"
            className="form-control"
            name="applying_for"
            value={this.state.fields.applying_for}
            onChange={this.handleChange}
          />
        </div>
      );
    } else {
      return (
        <div className="col-md-4">
          <label>Applying for:</label>
          <input
            type="text"
            className="form-control"
            name="applying_for"
            value={this.state.fields.applying_for}
            onChange={this.handleChange}
            disabled
          />
        </div>
      );
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop);
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.dataURLtoFile(reader.result, "cropped.jpg");
      };
    });
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({ croppedImage: croppedImage });
  }

  addOrganization = () => {
    let previousOrgs = this.state.previousOrgs;
    previousOrgs = [
      ...previousOrgs,
      {
        orgName: "",
        desg: "",
        responsiblities: "",
        serviceFrom: "",
        serviceTo: "",
        reportedTo: "",
      },
    ];
    this.setState({
      previousOrgs: previousOrgs,
    });
  };

  addQualification = () => {
    let qualifications = this.state.qualifications;
    qualifications = [
      ...qualifications,
      {
        edu: "",
        name: "",
        subject: "",
        uni: "",
        insti: "",
        start: "",
        end: "",
        marks: "",
        mode: "",
      },
    ];
    this.setState({
      qualifications: qualifications,
    });
  };

  handlePreviousOrgChange = (e) => {
    let previousOrgs = this.state.previousOrgs;
    const { name, value } = e.target;
    let n = name.split("_");
    let index = parseInt(n[1]);
    let key = n[0];

    previousOrgs[index][key] = value;

    this.setState({
      previousOrgs,
    });
  };

  handleQualificationChange = (e) => {
    let qualifications = this.state.qualifications;
    const { name, value } = e.target;
    let n = name.split("_");
    let index = parseInt(n[1]);
    let key = n[0];

    qualifications[index][key] = value;

    this.setState({
      qualifications,
    });
  };

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="form-container">
            <div className="head-c">
              <div className="heading text-center">
                <h1 className="form-heading">Application Form</h1>
              </div>
              <div className="img-c">
                <div className="img-holder form-group">
                  {/* image cropper and uploader added by yogesh 28-03-2021 */}
                  <ImageUploaderCropper />
                  {/* below commented by yogesh 28-03-2021  (Deprecated)*/}
                  {/* {this.state.profileImg ? (
                    <img
                      src={this.state.profileImg}
                      alt=""
                      className="profile_img"
                      onClick={() =>
                        document.querySelector("#selectedFile").click()
                      }
                    />
                  ) : (
                    <button
                      onClick={() =>
                        document.querySelector("#selectedFile").click()
                      }
                      className="btn btn-secondary"
                    >
                      {this.state.profileImg
                        ? "Select Different File"
                        : "Upload Profile Image"}
                    </button>
                  )}

                  <input
                    type="file"
                    className="form-control-file"
                    style={{ display: "none" }}
                    accept="image/*"
                    id="selectedFile"
                    onChange={this.imageHandler}
                  /> */}
                  {/* {this.state.profileImg && (
                      <ReactCrop
                        src={this.state.profileImg}
                        crop={this.state.crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        style={{ height: "auto" }}
                      />
                    )} */}
                </div>
              </div>
            </div>

            <form
              method="post"
              name="RecruitmentForm"
              onSubmit={this.submitCareerForm}
            >
              <div className="inner-container bottom-margin">
                <div className="head-strip">Applying for</div>
                <div className="container row bottom-margin">
                  <div className="col-md-4">
                    <label>Department*:</label>
                    <select
                      className="form-control"
                      name="department"
                      value={this.state.fields.department}
                      onChange={this.handleChange}
                      required
                    >
                      <option>Choose One..</option>
                      {this.state.departments.map((dep) => (
                        <option value={dep.dep} key={dep.id}>
                          {dep.dep}
                        </option>
                      ))}
                    </select>
                  </div>
                  {this.renderPostOptions()}
                  {this.renderOthersPost()}
                </div>
              </div>

              <div className="inner-container bottom-margin">
                <div className="head-strip">Personal Information</div>
                <div className="container bottom-margin">
                  <div className="row form-group">
                    <div className="col-md-3 form-group">
                      <label>Title:*</label>
                      <select
                        name="title"
                        className="form-control"
                        value={this.state.fields.title}
                        onChange={this.handleChange}
                      >
                        <option value="Dr.">Dr.</option>
                        <option value="Mr.">Mr.</option>
                        <option value="Ms.">Ms.</option>
                      </select>
                    </div>
                    <div className="col-md-3 form-group">
                      <label>First Name*:</label>
                      <input
                        type="text"
                        name="first_name"
                        className="form-control"
                        value={this.state.fields.first_name}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-3 form-group">
                      <label>Last Name*:</label>
                      <input
                        type="text"
                        name="last_name"
                        className="form-control"
                        value={this.state.fields.last_name}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-3 form-group">
                      <label>Date of birth*:</label>
                      <input
                        type="date"
                        name="dob"
                        className="form-control"
                        value={this.state.fields.dob}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-3 form-group">
                      <label>Father's Name*:</label>
                      <input
                        type="text"
                        name="father_name"
                        className="form-control"
                        value={this.state.fields.father_name}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-3 form-group">
                      <label>Gender*:</label>
                      <select
                        className="form-control"
                        name="gender"
                        value={this.state.fields.gender}
                        onChange={this.handleChange}
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                    <div className="col-md-3 form-group">
                      <label>Email ID*:</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        value={this.state.fields.email}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-3 form-group">
                      <label>Mobile No*:</label>
                      <input
                        type="text"
                        name="mobile"
                        value={this.state.fields.mobile}
                        onChange={this.handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-6 form-group">
                      <label style={{ fontWeight: "bold" }}>
                        Permanent Address*:
                      </label>
                      <div className="form-group">
                        <label>House No.*:</label>
                        <input
                          type="text"
                          name="perm_add_line_1"
                          className="form-control"
                          value={this.state.fields.perm_add_line_1}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>Street and Landmark*:</label>
                        <input
                          type="text"
                          name="perm_add_line_2"
                          className="form-control"
                          value={this.state.fields.perm_add_line_2}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>Locality/Area*:</label>
                        <input
                          type="text"
                          name="perm_add_line_3"
                          className="form-control"
                          value={this.state.fields.perm_add_line_3}
                          onChange={this.handleChange}
                          required
                        />
                        <div className="row form-group mt-2">
                          <div className="col-md-4 form-group">
                            <label>City*:</label>
                            <input
                              type="text"
                              name="perm_add_city"
                              className="form-control"
                              value={this.state.fields.perm_add_city}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="col-md-4 form-group">
                            <label>Pin code*:</label>
                            <input
                              type="text"
                              name="perm_add_zip"
                              className="form-control"
                              value={this.state.fields.perm_add_zip}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="col-md-4 form-group">
                            <label>State*:</label>
                            <input
                              type="text"
                              name="perm_add_state"
                              className="form-control"
                              value={this.state.fields.perm_add_state}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 form-group">
                      <label style={{ fontWeight: "bold" }}>
                        Current Address*:
                      </label>
                      <div className="form-group">
                        <label>House No.*:</label>
                        <input
                          type="text"
                          name="curr_add_line_1"
                          className="form-control"
                          value={this.state.fields.curr_add_line_1}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>Street and Landmark*:</label>
                        <input
                          type="text"
                          name="curr_add_line_2"
                          className="form-control"
                          value={this.state.fields.curr_add_line_2}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>Area/Locality*:</label>
                        <input
                          type="text"
                          name="curr_add_line_3"
                          className="form-control"
                          value={this.state.fields.curr_add_line_3}
                          onChange={this.handleChange}
                          required
                        />
                        <div className="row form-group mt-2">
                          <div className="col-md-4 form-group">
                            <label>City*:</label>
                            <input
                              type="text"
                              name="curr_add_city"
                              className="form-control"
                              value={this.state.fields.curr_add_city}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="col-md-4 form-group">
                            <label>Pin code*:</label>
                            <input
                              type="text"
                              name="curr_add_zip"
                              className="form-control"
                              value={this.state.fields.curr_add_zip}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="col-md-4 form-group">
                            <label>State*:</label>
                            <input
                              type="text"
                              name="curr_add_state"
                              className="form-control"
                              value={this.state.fields.curr_add_state}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner-container bottom-margin">
                <div className="head-strip">Qualification Details</div>
                <div className="container bottom-margin">
                  {/* <p style={{ color: "red" }}>
                    In case %/Marks/CGPA/GPA is not applicable, please fill
                    0(Zero).
                  </p> */}
                  {this.state.qualifications.map((q, index) => (
                    <div className="quali-sec">
                      <div class="row inline-form form-group">
                        <div className="col-md-2" style={{ marginTop: "1%" }}>
                          <label>Select Qualification*:</label>
                        </div>
                        <div className="col-md-2">
                          <select
                            className="form-control"
                            name={`edu_${index}`}
                            value={q.edu}
                            onChange={this.handleQualificationChange}
                          >
                            <option>Choose One..</option>
                            {this.state.eduOptions.map((o) => (
                              <option value={o}>{o}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row quali-row">
                        {/* <div className="col-md-2">
                        <label>Degree/Diploma</label>
                        <select className="form-control">
                          <option value="Degree">Degree</option>
                          <option value="Diploma">Diploma</option>
                        </select>
                      </div> */}
                        <div className="col-md-2">
                          <label>Degree Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            name={`name_${index}`}
                            value={q.name}
                            onChange={this.handleQualificationChange}
                          />
                        </div>
                        <div className="col-md-2">
                          <label>Main Subject:</label>
                          <input
                            type="text"
                            className="form-control"
                            name={`subject_${index}`}
                            value={q.subject}
                            onChange={this.handleQualificationChange}
                          />
                        </div>
                        <div className="col-md-2">
                          <label>University:</label>
                          <input
                            type="text"
                            className="form-control"
                            name={`uni_${index}`}
                            value={q.uni}
                            onChange={this.handleQualificationChange}
                          />
                        </div>
                        <div className="col-md-2">
                          <label>Institute Name, City:</label>
                          <input
                            type="text"
                            className="form-control"
                            name={`insti_${index}`}
                            value={q.insti}
                            onChange={this.handleQualificationChange}
                          />
                        </div>
                        <div className="col-md-1">
                          <label>Start:</label>
                          <input
                            type="number"
                            placeholder="YY"
                            className="form-control"
                            name={`start_${index}`}
                            value={q.start}
                            onChange={this.handleQualificationChange}
                          />
                        </div>
                        <div className="col-md-1">
                          <label>End:</label>
                          <input
                            type="text"
                            placeholder="YY"
                            className="form-control"
                            name={`end_${index}`}
                            value={q.end}
                            onChange={this.handleQualificationChange}
                          />
                        </div>
                        <div className="col-md-1">
                          <label>Marks/CGPA:</label>
                          <input
                            type="text"
                            className="form-control"
                            name={`marks_${index}`}
                            value={q.marks}
                            onChange={this.handleQualificationChange}
                          />
                        </div>
                        <div className="col-md-1">
                          <label>Mode:</label>
                          <select
                            className="form-control"
                            name={`mode_${index}`}
                            value={q.mode}
                            onChange={this.handleQualificationChange}
                          >
                            <option value="1">Full Time</option>
                            <option value="0">Part Time</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className="text-right form-group"
                  style={{ marginRight: "2%" }}
                >
                  <p
                    className="btn btn-primary"
                    onClick={this.addQualification}
                  >
                    Add Qualification
                  </p>
                </div>
              </div>

              <div className="inner-container bottom-margin">
                <div className="head-strip">Experience</div>
                <div className="container bottom-margin">
                  <div className="row">
                    <div className="col-md-2" style={{ paddingTop: "3%" }}>
                      <p className="text-center">Total Experiecne:</p>
                    </div>
                    <div className="col-md-2">
                      <label>Years:</label>
                      <input
                        text="number"
                        className="form-control"
                        name="total_exp_years"
                        value={this.state.fields.total_exp_years}
                        onChange={this.handleChange}
                        min="0"
                      />
                    </div>
                    <div className="col-md-2">
                      <label>Months:</label>
                      <input
                        text="number"
                        className="form-control"
                        name="total_exp_months"
                        min="0"
                        value={this.state.fields.total_exp_years}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "1%" }}>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-8">
                          <label>Current Organization:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="current_org_name"
                            value={this.state.fields.curr_org_name}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col-md-4">
                          <label>Designation:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="current_org_desg"
                            value={this.state.fields.curr_org_desg}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "1%" }}>
                        <div className="col-md-4">
                          <label>Reporting To:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="current_org_reporting_to"
                            placeholder="Ex: Senior Manager"
                            value={this.state.fields.curr_org_reporting_to}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col-md-4">
                          <label>Service From:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="YY"
                            name="current_org_from"
                            value={this.state.fields.curr_org_from}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col-md-4">
                          <label>Service To:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="current_org_to"
                            placeholder="YY"
                            value={this.state.fields.curr_org_to}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <label>Responsiblities:</label>
                          <textarea
                            type="text"
                            className="form-control"
                            name="current_org_responsiblities"
                            value={this.state.fields.curr_org_responsiblities}
                            onChange={this.handleChange}
                            rows="4"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  {this.state.previousOrgs.map((org, index) => (
                    <div>
                      <div className="row" style={{ marginTop: "1%" }}>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-8">
                              <label>Previous Organization:</label>
                              <input
                                type="text"
                                className="form-control"
                                name={`orgName_${index}`}
                                value={org.orgName}
                                onChange={this.handlePreviousOrgChange}
                              />
                            </div>
                            <div className="col-md-4">
                              <label>Designation:</label>
                              <input
                                type="text"
                                className="form-control"
                                name={`desg_${index}`}
                                value={org.desg}
                                onChange={this.handlePreviousOrgChange}
                              />
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: "1%" }}>
                            <div className="col-md-4">
                              <label>Reporting To:</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Ex: Senior Manager"
                                name={`reportedTo_${index}`}
                                value={org.reportedTo}
                                onChange={this.handlePreviousOrgChange}
                              />
                            </div>
                            <div className="col-md-4">
                              <label>Service From:</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="YY"
                                name={`serviceFrom_${index}`}
                                value={org.serviceFrom}
                                onChange={this.handlePreviousOrgChange}
                              />
                            </div>
                            <div className="col-md-4">
                              <label>Service To:</label>
                              <input
                                type="text"
                                className="form-control"
                                name={`serviceTo_${index}`}
                                value={org.serviceTo}
                                onChange={this.handlePreviousOrgChange}
                                placeholder="YY"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12">
                              <label>Responsiblities:</label>
                              <textarea
                                type="text"
                                className="form-control"
                                name={`responsiblities_${index}`}
                                value={org.responsiblities}
                                onChange={this.handlePreviousOrgChange}
                                rows="4"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
                <div className="text-right" style={{ marginRight: "2%" }}>
                  <div
                    className="btn btn-primary bottom-margin"
                    onClick={this.addOrganization}
                  >
                    Add Organization
                  </div>
                </div>
              </div>

              <div className="inner-container bottom-margin">
                <div className="head-strip">
                  How do you come to know about us?
                </div>
                <div className="container bottom-margin">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Select Refrence:</label>
                      <select
                        name="refrence"
                        className="form-control"
                        value={this.state.fields.refrence}
                        onChange={this.handleChange}
                      >
                        <option value="Newspaper">Newspaper</option>
                        <option value="Social Media">Social Media</option>
                        <option value="Hiring Platforms">
                          Hiring Platforms
                        </option>
                        <option value="Friends/Colleague">
                          Friends/Colleague
                        </option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label>Specify:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="ref_explain"
                        value={this.state.fields.ref_explain}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner-container bottom-margin">
                <div className="head-strip">Upload your Resume</div>
                <div className="container bottom-margin">
                  <label>Upload your CV</label>
                  <Form.Group as={Col} md="4">
                    <Form.File id="CV" onChange={this.cvHandler} />
                  </Form.Group>
                </div>
              </div>
              <div className="text-center">
                <button className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default RecruitmentForm;

import React, { Component } from "react";
import Jumbotron from "react-bootstrap/Jumbotron";

class Banner extends Component {
  state = {};
  render() {
    const { bannerHeight, bgImage, bgColor, textColor, title, subTitle } = {
      ...this.props.details,
    };
    const styles = {
      height: `${bannerHeight}`,
      color: `${textColor}`,
      backgroundColor: `${bgColor}`,
      backgroundImage: `url(${bgImage})`,
      borderRadius: "0",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative",
    };
    return (
      <React.Fragment>
        <Jumbotron style={styles}>
          <div className="banner-text">
            <h1 className="text-center">{title}</h1>
            <p className="text-center">{subTitle}</p>
            <p></p>
          </div>
        </Jumbotron>
      </React.Fragment>
    );
  }
}

export default Banner;

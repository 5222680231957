import React, { Component } from "react";
import Card from "react-bootstrap/Card";

class ChooseCard extends Component {
  state = {};
  render() {
    const { heading, icon, content } = this.props;
    return (
      <Card className="p-2 choose-card">
        <div className="text-center">
          <Card.Img
            variant="top"
            src={icon}
            className="img-fluid choose-card-icon"
          />
        </div>
        <Card.Body>
          <Card.Title as="h5">{heading}</Card.Title>
          <div className="choose-card-text text-center">{content}</div>
        </Card.Body>
      </Card>
    );
  }
}

export default ChooseCard;

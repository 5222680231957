import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ModalPopUp from "./commons/modal";
import axios from "axios";

class OurServices extends Component {
  state = {
    services: [],

    openModal: false,
    modalBody: "",
    modalTitle: "",
    modalImage: "",
    address: "",
  };

  async componentDidMount() {
    const res = await axios.post(
      "https://avrn.in/admin/Api_controller/get_all_service"
    );

    if (res.data.status === 200) {
      const services = res.data.services;
      this.setState({ services });
    }
  }

  handleServiceClick = (service) => {
    const openModal = true;
    const modalTitle = service.title;
    const modalBody = service.md_description;
    const modalImage = service.modal_img ? service.modal_img : service.icon;
    const address = `/service/${service.service_id}`;
    this.setState({ openModal, modalTitle, modalBody, modalImage, address });
  };

  handleModalClose = () => {
    const openModal = false;
    const modalTitle = "";
    const modalBody = "";
    const modalImage = "";
    const address = "";
    this.setState({ openModal, modalTitle, modalBody, modalImage, address });
  };

  handleKnowMoreClick = () => {};

  render() {
    const { openModal, modalTitle, modalBody, modalImage, address } =
      this.state;

    return (
      <Container className="services-container" fluid id="servicesHome">
        <h3 className="text-center">What are you looking for?</h3>
        <Container>
          <Row>
            {this.state.services.map((service) => (
              <Col
                md={6}
                className="service-col"
                key={service.service_id}
                onClick={() => this.handleServiceClick(service)}
              >
                <Card className="service-card">
                  <Row>
                    <Col xs={2}>
                      <img
                        src={service.icon}
                        alt="icon"
                        width="100%"
                        className="service-icon"
                      />
                    </Col>
                    <Col xs={10}>
                      <Card.Body>
                        <Card.Title as="h4">{service.title}</Card.Title>
                        <br />
                        <Card.Text>{service.sm_description}</Card.Text>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
        <ModalPopUp
          openModal={openModal}
          modalTitle={modalTitle}
          modalImage={modalImage}
          modalBody={modalBody}
          address={address}
          onModalClose={this.handleModalClose}
        />
      </Container>
    );
  }
}

export default OurServices;

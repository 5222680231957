import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import arduino from "../tech_logos/arduino.png";
import ci from "../tech_logos/ci.png";
import esp8266 from "../tech_logos/esp8266.png";
import firebase from "../tech_logos/firebase.png";
import mysql from "../tech_logos/mysql.png";
import nodejs from "../tech_logos/nodejs.png";
import php from "../tech_logos/php.png";
import python from "../tech_logos/python.png";
import react from "../tech_logos/react.png";
import redux from "../tech_logos/redux.png";
import pi from "../tech_logos/pi.png";
import gcp from "../tech_logos/gcp.png";

class TechUsed extends Component {
  state = {
    images: [
      {
        tech_name: "ReactJS",
        img: react,
      },
      {
        tech_name: "CodeIgniter",
        img: ci,
      },
      {
        tech_name: "esp8266",
        img: esp8266,
      },
      {
        tech_name: "Firebase",
        img: firebase,
      },
      {
        tech_name: "MySql",
        img: mysql,
      },
      {
        tech_name: "NodeJs",
        img: nodejs,
      },
      {
        tech_name: "PHP",
        img: php,
      },
      {
        tech_name: "Python",
        img: python,
      },
      {
        tech_name: "Redux",
        img: redux,
      },
      {
        tech_name: "Arduino",
        img: arduino,
      },
      {
        tech_name: "Raspberry PI",
        img: pi,
      },
      {
        tech_name: "GCP",
        img: gcp,
      },
    ],
  };
  render() {
    return (
      <Container className="tech-used-container" fluid>
        <h3 className="text-center">Few Of The Technologies We Use</h3>
        <div className="text-center">
          {this.state.images.map((img) => (
            <img
              src={img.img}
              alt="tech_logo"
              className="techLogo"
              key={img.tech_name}
              title={img.tech_name}
            />
          ))}
        </div>
      </Container>
    );
  }
}

export default TechUsed;

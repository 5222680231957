import React, { Component } from "react";
import iotHomeAuto from "../images/iotHomeAuto.jpg";
import Banner from "./commons/banner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import KnowMoreButton from "./commons/knowMoreButton";
import TextTruncate from "react-text-truncate";

class ServicePage extends Component {
  state = {
    bannerDetails: {
      bannerHeight: "50vh",
      bgImage: iotHomeAuto,
      title: "",
      subTitle: "",
      bgColor: "#6593F5",
      textColor: "#000000",
    },

    products: [],

    service: {},

    paras: [],
  };

  async componentDidMount() {
    const service_id = this.props.match.params.id;
    const res = await axios.post(
      "https://avrn.in/admin/Api_controller/get_service",
      {
        service_id: service_id,
      }
    );
    if (res.data.status === 200) {
      const service = res.data.service;
      const bannerDetails = this.state.bannerDetails;
      const paras = res.data.paras;
      bannerDetails.bgImage = service.banner;
      this.setState({ service, paras, bannerDetails });
    }
    const product_res = await axios.post(
      "https://avrn.in/admin/Api_controller/get_service_product",
      {
        service_id: service_id,
      }
    );
    if (product_res.data.status === 200) {
      const products = product_res.data.service_products;
      this.setState({ products });
    }
  }

  render() {
    const renderParagraphs = (para, index) => {
      if (index % 2 === 0) {
        return (
          <Row className="ppage-row">
            <Col md={6} className="sm-display">
              <img
                src={para.image}
                width="100%"
                height="300px"
                alt="bg"
                className="ppage-product-img"
              />
            </Col>
            <Col md={6}>
              <div className="service-content-col">
                <h2 className="text-center">{para.paragraph_title}</h2>
                <br />
                <span className="text-justify">{para.paragraph}</span>
              </div>
            </Col>
            <Col md={6} className="nm-display">
              <img
                src={para.image}
                width="100%"
                height="300px"
                alt="bg"
                className="ppage-product-img"
              />
            </Col>
          </Row>
        );
      } else {
        return (
          <Row className="ppage-row">
            <Col md={6}>
              <img
                src={para.image}
                width="100%"
                height="300px"
                alt="bg"
                className="ppage-product-img"
              />
            </Col>
            <Col md={6}>
              <div className="service-content-col">
                <h2 className="text-center">{para.paragraph_title}</h2>
                <br />
                <span className="text-justify">{para.paragraph}</span>
              </div>
            </Col>
          </Row>
        );
      }
    };
    return (
      <React.Fragment>
        <Banner details={this.state.bannerDetails} />
        <Container>
          <div className="text-center">
            <h2>{this.state.service.title}</h2>
            <br />

            <span style={{ fontSize: "13px" }}>
              {this.state.service.md_description}
            </span>
          </div>
        </Container>
        <Container fluid className="service-desc-container">
          {this.state.paras.map((para, index) => (
            <div className="para-div" key={para.p_id}>
              {renderParagraphs(para, index)}
            </div>
          ))}
        </Container>
        <Container className="service-products-container">
          <h3 className="text-center">Products</h3>
          {/* {this.state.products.map((product) => (
            <div key={product.product_id}>
              <h3>{product.title}</h3>
              <p>{product.sm_description}</p>
              <div className="service-products-variants-container">
                {product.variants.map((variant) => (
                  <div
                    className="service-product-variants-card-container"
                    key={
                      String(product.product_id) + String(variant.variant_id)
                    }
                  >
                    <Card className="service-product-variants-card">
                      <Card.Img
                        variant="top"
                        src={variant.img_one}
                        className="w-100"
                      />
                      <Card.Title className="text-center p-2">
                        {variant.title}
                      </Card.Title>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          ))} */}

          <Row>
            {this.state.products.map((product) => (
              <Col
                md={4}
                key={product.product_id}
                className="service-product-col"
              >
                <div className="service-product-col-items">
                  <div>
                    <img
                      src={product.display_img}
                      width="100%"
                      height="250px"
                      alt="img"
                    />
                  </div>
                  <div className="service-product-col-content">
                    <div className="service-product-col-title">
                      <h3 className="text-center">{product.title}</h3>
                    </div>
                    <TextTruncate
                      line={3}
                      element="p"
                      truncateText="..."
                      text={product.md_description}
                    />
                  </div>
                  <div className="service-product-col-content-button">
                    <KnowMoreButton
                      address={`/products/${product.product_id}`}
                    />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default ServicePage;

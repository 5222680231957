import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import logo from "../images/AVRN.png";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import axios from "axios";

class NavBar extends Component {
  state = {
    services: [],
  };

  async componentDidMount() {
    const res = await axios.post(
      "https://avrn.in/Api_controller/get_all_service"
    );

    if (res.data.status === 200) {
      const services = res.data.services;
      this.setState({ services });
    }
  }

  // handleDropDownClick = (service) => {
  //   this.props.history.replace(`allProducts/${service.service}`);
  // };

  render() {
    return (
      <Navbar
        collapseOnSelect
        expand="lg"
        sticky="top"
        bg="white"
        variant="light"
        className="shadow pr-3"
      >
        <Navbar.Brand href="/home">
          {" "}
          <img
            src={logo}
            height="30"
            className="d-inline-block align-top"
            alt="AVRN logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <NavLink className="nav-item nav-link" to="/home">
              Home
            </NavLink>
            {/* <NavLink className="nav-item nav-link" to="/allProducts">
            Products
          </NavLink> */}
            {/* <NavDropdown title="Prodcuts" id="nav-dropdown">
              {this.state.services.map((service) => (
                <NavDropdown.Item
                  key={service.service_id}
                  href={`/allProducts/${service.service_id}`}
                >
                  {service.title}
                </NavDropdown.Item>
              ))}
            </NavDropdown> */}
            <a className="nav-item nav-link" href="/home#servicesHome">
              Services
            </a>
            <a className="nav-item nav-link" href="/careers">
              Careers
            </a>
            {/* <NavDropdown title="Careers" id="nav-dropdown">
              <NavDropdown.Item href="/careers">
                Current Positions
              </NavDropdown.Item>
              <NavDropdown.Item href="/internForm">Internship</NavDropdown.Item>
            </NavDropdown> */}
            {/* <a className="nav-item nav-link" href="/internForm">
              Internship Form
            </a> */}
            {/*
            <a className="nav-item nav-link" href="/home#featuredHome">
              Featured Products
            </a>*/}
            <a className="nav-item nav-link" href="/home#contactUsHome">
              Contact Us
            </a>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavBar;

import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import axios from "axios";

class OurProducts extends Component {
  state = {
    productsList: [
      {
        id: 1,
        title: "Sanitization Chamber",
        description:
          "In the ongoing fight against the Corona Virus pandemic, AVRN Labs Santization Chamber can be used to disinfect persons, animals, material etc. These chambers find utility in public as well private places like entrance of Railway Stations, Office Buildings & Stores, etc. These fully automatic chambers are walkthrough enclosures accommodating one person at a time. As the presence of person/material  is sensed  inside the disinfectant is sprayed on the entrant for a set duration.",
        is_selected: false,
        img: "",
      },
      {
        id: 2,
        title: "Automatic Sanitizer Dispenser",
        description:
          "COVID9 has given a tremendous boom to touchless technology. AVRN Labs in support of this touchless technology presents Automatic Sanitizer/Soap Dispenser. This Automatic Sanitiser/Soap dispenser is fully automatic. Whenever a movement of hand is detected under the nozzle of the dispenser a controlled amount of liquid is dispensed. The amount of liquid dispensed can be controlled as required. Post COVID Automatic Sanitiser/Soap dispenser  can now be found in the entrances to offices, hotels, shops, nursing homes and hospitals, home, many public washrooms.",
        is_selected: false,
      },
      {
        id: 3,
        title: "Smart Switch Board",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem vitae officiis minima nostrum maiores nobis, nisi vero libero tempora eligendi aspernatur",
        is_selected: false,
      },
      {
        id: 4,
        title: "Intellihood",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem vitae officiis minima nostrum maiores nobis, nisi vero libero tempora eligendi aspernatur",
        is_selected: false,
      },
      {
        id: 5,
        title: "Smart Irrigation System",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem vitae officiis minima nostrum maiores nobis, nisi vero libero tempora eligendi aspernatur",
        is_selected: false,
      },
      {
        id: 6,
        title: "Water Level Indicator",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem vitae officiis minima nostrum maiores nobis, nisi vero libero tempora eligendi aspernatur",
        is_selected: false,
      },
      {
        id: 7,
        title: "Smart Locks",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem vitae officiis minima nostrum maiores nobis, nisi vero libero tempora eligendi aspernatur",
        is_selected: false,
      },
    ],

    selectedProduct: null,
  };

  async componentDidMount() {
    // const res = await axios.post("http://admin.avrn.in/featured");
    // if (res.data.status === 200) {
    //   const productsList = res.data.featured;
    //   this.setState({ productsList });
    // }

    let productsList = [...this.state.productsList];
    productsList[0].is_selected = true;
    let selectedProduct = productsList[0];
    this.setState({ productsList });
    this.setState({ selectedProduct: selectedProduct });
  }

  handleClick = (product) => {
    const productsList = [...this.state.productsList];
    for (let p of productsList) {
      p.is_selected = false;
    }
    const index = productsList.indexOf(product);
    productsList[index] = { ...productsList[index] };
    productsList[index].is_selected = true;
    const selectedProduct = product;
    this.setState({ productsList, selectedProduct });
  };

  render() {
    const selectedStyle = {
      background: "#b2ab25",
      transform: "scale(1.1)",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    };
    const renderSelectedProduct = () => {
      if (this.state.selectedProduct != null) {
        return (
          <Row>
            <Col md={7}>
              <div className="product-text">
                <h4 className="text-center">
                  {this.state.selectedProduct.title}
                </h4>
                <br></br>
                <p className="text-justify product-desc">
                  {this.state.selectedProduct.md_description}
                </p>
              </div>
            </Col>
            <Col md={5}>
              <img
                src={this.state.selectedProduct.display_img}
                className="img-fluid"
                alt="product"
                style={{ width: "100%", height: "100%" }}
              />
            </Col>
          </Row>
        );
      } else {
        return <div></div>;
      }
    };

    return (
      <Container className="product-container" fluid id="featuredHome">
        <h3 className="text-center">Featured Products</h3>
        <hr></hr>
        <Container>
          <Row className="product-row">
            <Col md={3}>
              <ListGroup className="product-list">
                {this.state.productsList.map((product) => (
                  <ListGroup.Item
                    className="product-list-item text-center"
                    key={product.title}
                    onClick={() => this.handleClick(product)}
                    style={product.is_selected ? selectedStyle : {}}
                  >
                    <div className="product-list-item-name">
                      {product.title}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
            <Col md={9}>
              <div className="p-2">{renderSelectedProduct()}</div>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default OurProducts;

import React from "react";
import "./App.css";
import NavBar from "./components/navbar";
import Footer from "./components/footer";
import Home from "./components/home";
import { Switch, Route, Redirect } from "react-router-dom";
import AllProducts from "./components/allProducts";
import Product from "./components/productpage";
import ServicePage from "./components/servicepage";
import InternForm from "./components/internForm";
import CareersPage from "./components/careers";
import RecruitmentForm from "./components/recruitmentForm";

function App() {
  return (
    <React.Fragment>
      <div>
        <NavBar />
        <div>
          <Switch>
            <Route path="/home" component={Home} />
            <Route path="/internForm" component={InternForm} />
            <Route path="/careers" component={CareersPage} />
            <Route path="/career-form" component={RecruitmentForm} />
            <Route path="/products/:id" component={Product} />
            <Route path="/allProducts/:id" component={AllProducts} />
            <Route path="/service/:id" component={ServicePage} />
            <Redirect from="/" exact to="/home" />
          </Switch>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default App;

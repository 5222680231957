import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaInfinity } from "react-icons/fa";

class KeyPoints extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid key-container">
          <Row>
            <Col md={3}>
              <h2>
                Some of
                <br />
                our facts
              </h2>
            </Col>
            <Col md={3}>
              50+ Clients
              <hr />
              <p>WorldWide</p>
            </Col>
            <Col md={3}>
              150+ Projects
              <hr />
              <p>Innovations</p>
            </Col>
            <Col md={3}>
              <FaInfinity />
              <hr />
              <p>Countless Sleepless nights</p>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default KeyPoints;

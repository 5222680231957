import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Banner from "./commons/banner";
import Col from "react-bootstrap/Col";
import bg from "../images/sHbg.jpg";
import isFeaturedIcon from "../images/isFeaturedIcon.svg";
import hasAppIcon from "../images/hasAppIcon.svg";
import isWifiEnabledIcon from "../images/isWifiEnabledIcon.svg";
import variantsIcon from "../images/variantsIcon.svg";
import axios from "axios";
import ModalPopUp from "./commons/modal";

class Product extends Component {
  state = {
    bannerDetails: {
      bannerHeight: "60vh",
      bgImage: bg,
      title: "",
      subTitle: "",
      bgColor: "#6595F5",
      textColor: "#ffffff",
    },

    variations: [],
    product: {},
    paras: [],

    openModal: false,
    modalBody: "",
    modalTitle: "",
    modalImage: "",
    address: "",
  };

  async componentDidMount() {
    const product_id = this.props.match.params.id;
    const res = await axios.post(
      "https://avrn.in/admin/Api_controller/get_product",
      {
        product_id: product_id,
      }
    );
    if (res.data.status === 200) {
      const product = res.data.product;
      let bannerDetails = this.state.bannerDetails;
      const paras = res.data.paras;
      bannerDetails.bgImage = product.banner;
      this.setState({ product, paras, bannerDetails });
    }

    const v_res = await axios.post(
      "https://avrn.in/admin/Api_controller/get_all_product_variants",
      {
        product_id: product_id,
      }
    );
    if (v_res.data.status === 200) {
      const variations = v_res.data.variants;
      this.setState({ variations });
    }
  }

  handleVariantClick = (variation) => {
    const openModal = true;
    const modalTitle = variation.title;
    const modalBody = variation.md_description;
    const modalImage = variation.img_one;
    const address = "";
    this.setState({ openModal, modalTitle, modalBody, modalImage, address });
  };

  handleModalClose = () => {
    const openModal = false;
    const modalTitle = "";
    const modalBody = "";
    const modalImage = "";
    const address = "";
    this.setState({ openModal, modalTitle, modalBody, modalImage, address });
  };

  render() {
    const product = this.state.product;
    const { openModal, modalTitle, modalBody, modalImage, address } =
      this.state;

    const renderParagraphs = (para, index) => {
      if (index % 2 === 0) {
        return (
          <Row className="ppage-row">
            <Col md={6} className="sm-display">
              <img
                src={para.image}
                width="100%"
                height="300px"
                alt="bg"
                className="ppage-product-img"
              />
            </Col>
            <Col md={6}>
              <div className="service-content-col">
                <h2 className="text-center">{para.paragraph_title}</h2>
                <br />
                <span className="text-justify">{para.paragraph}</span>
              </div>
            </Col>
            <Col md={6} className="nm-display">
              <img
                src={para.image}
                width="100%"
                height="300px"
                alt="bg"
                className="ppage-product-img"
              />
            </Col>
          </Row>
        );
      } else {
        return (
          <Row className="ppage-row">
            <Col md={6}>
              <img
                src={para.image}
                width="100%"
                height="300px"
                alt="bg"
                className="ppage-product-img"
              />
            </Col>
            <Col md={6}>
              <div className="service-content-col">
                <h2 className="text-center">{para.paragraph_title}</h2>
                <br />
                <span className="text-justify">{para.paragraph}</span>
              </div>
            </Col>
          </Row>
        );
      }
    };

    return (
      <div className="ppage-main-container">
        <Banner details={this.state.bannerDetails} />
        <Container className="text-center product-desc">
          <h2>{product.title}</h2>
          <br />
          {product.md_description}
          <Container style={{ marginTop: "5%" }}>
            <Row>
              <Col xs={3}>
                <img
                  src={isFeaturedIcon}
                  alt="icon"
                  width="100%"
                  height="80px"
                />
              </Col>
              <Col xs={3}>
                <img
                  src={isWifiEnabledIcon}
                  alt="icon"
                  width="100%"
                  height="80px"
                />
              </Col>
              <Col xs={3}>
                <img src={hasAppIcon} alt="icon" width="100%" height="80px" />
              </Col>
              <Col xs={3}>
                <img src={variantsIcon} alt="icon" width="100%" height="80px" />
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className="service-desc-container">
          {this.state.paras.map((para, index) => (
            <div className="para-div" key={para.p_id}>
              {renderParagraphs(para, index)}
            </div>
          ))}
        </Container>
        <Container className="product-variation-container">
          <h4 className="text-center">Variations of Products</h4>
          <br />
          <br />
          <Row>
            {this.state.variations.map((variation) => (
              <Col
                md={3}
                key={variation.variant_id}
                className="variation-col"
                onClick={() => this.handleVariantClick(variation)}
              >
                <img
                  src={variation.img_one}
                  width="100%"
                  height="200px"
                  alt="variation"
                />
                <div className="variation-text">
                  <div className="text-center">
                    <p className="pt-2">{variation.title}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
        <ModalPopUp
          openModal={openModal}
          modalTitle={modalTitle}
          modalImage={modalImage}
          modalBody={modalBody}
          address={address}
          onModalClose={this.handleModalClose}
        />
      </div>
    );
  }
}

export default Product;

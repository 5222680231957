import { Button, Dialog, DialogActions, DialogContent, Avatar } from '@material-ui/core';
import React from 'react';
import ReactCrop from 'react-image-crop';
import { withStyles } from '@material-ui/core';
const useStyles = (theme) => ({
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        marginLeft:'8px'
    },
    shift1: {
        marginTop: 5,
        marginLeft: 3,
    },
    shift2: {
        marginTop: 5,
        marginLeft: 3
    },
    content:{
        textAlign :'center'
    }
})
class ImageUploadCrooper extends React.Component {
    state={
        dialogOpen :  false,
        src: null,
        croppedImageUrl:"",
        crop: {
          unit: '%',
          width: 50,
          aspect: 1 / 1,
        },
        shape:"square"
    }
    onSelectFile = e => {
        // open dialog on image select
        if (e.target.files && e.target.files.length > 0) {
            this.setState({dialogOpen: true,
                src: null,   // reset all values for next upload file
                croppedImageUrl:"",
                crop: {
                  unit: '%',
                  width: 50,
                  aspect: 1 / 1,
                }
            });
          const reader = new FileReader();
          reader.addEventListener('load', () =>
            this.setState({ src: reader.result })
          );
          reader.readAsDataURL(e.target.files[0]);
        
        
    }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    // onCropComplete = crop => {
    //     this.makeClientCrop(crop);
    // };
    onCrop = crop => {
        console.log(this.state.crop)
        this.makeClientCrop(this.state.crop);
    };
    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };
    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );
          this.setState({ croppedImageUrl });
        }
    }
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
    
        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
            if (!blob) {
              //reject(new Error('Canvas is empty'));
              console.error('Canvas is empty');
              return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
          }, 'image/jpeg');
        });
    }
    //to close dialog on cancel
    cancel = ()=>{
        this.setState({dialogOpen: false, croppedImageUrl:null}) // close dialog on cancel
    }
    //on saving picture
    onSave = ()=>{
        this.setState({dialogOpen: false, croppedImageUrl:this.state.croppedImageUrl})
    }
    // on reset set values to origiinal but dont change src
    reset = () =>{
        this.setState({
            croppedImageUrl:"",
            crop: {
              unit: '%',
              width: 50,
              aspect: 1 / 1,
            }
        })
    }
    render() {
        const { crop, croppedImageUrl, src , dialogOpen} = this.state;
        const { classes } = this.props;
        return (
            <div>
                <Avatar variant={this.state.shape==="square"?"square":"circle"} alt="Remy Sharp" src={dialogOpen==false?this.state.croppedImageUrl:""} className={classes.large} />
                <Button variant="contained" component="label" color="primary" size="small" style={{fontSize:'9px',marginTop:'2%', marginLeft: '7%',width:'92%'}}>
                     Upload Image
                    <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange = {this.onSelectFile}
                    />
                </Button>
                <Dialog open ={this.state.dialogOpen} 
                    maxWidth={'xs'}
                    fullWidth={true}
                    scroll={'paper'}>
                    <DialogContent
                    className ={classes.content}>
                    {(src && croppedImageUrl==="")&& (
                    //show image once chosen by user and cropped image is empty
                    <ReactCrop
                        src={src}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        // onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        minHeight={90}
                        minWidth={90}
                        circularCrop={this.state.shape==="circle"?true:false}
                    />
                    )}
                    {croppedImageUrl && (  
                        //show when image is cropped by user
                        <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} style={{borderRadius:this.state.shape==='circle'?'50%':""}}/>
                    )}
                    </DialogContent>
                    <DialogActions>
                    <Button 
                        onClick={this.cancel} 
                        color="primary">
                            Cancel
                    </Button>
                    <Button 
                        onClick={this.reset} 
                        color="primary">
                        Reset
                    </Button>
                    {croppedImageUrl ? <Button 
                        onClick={this.onSave}
                        color="primary">
                        Save
                    </Button>
                    :
                        <Button 
                            onClick={this.onCrop}
                            color="primary">
                            Crop
                        </Button>
                    }
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
export default  withStyles(useStyles)(ImageUploadCrooper);
import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ChooseCard from "./commons/choosecard";
import teamIcon from "../images/teamIcon.svg";
import innoIcon from "../images/innoIcon.svg";
import qualityIcon from "../images/qualityIcon.svg";
import valuesIcon from "../images/valuesIcon.svg";

class ChooseUs extends Component {
  state = {
    data: [
      {
        id: 1,
        heading: "Dedicated Team",
        content:
          "We love what we do and it reflects in the solutions we produce. They are not only the best, but are also scalable and adaptable to specific organisational need",
        icon: teamIcon,
        is_clicked: false,
      },
      {
        id: 2,
        heading: "We are Innovative Tech Startup",
        content:
          "We have highly educated, and young talent base and diverse in all aspects of Website technology, Mobile Development, Native Development and Internet of Things",
        icon: innoIcon,
        is_clicked: false,
      },
      {
        id: 3,
        heading: "Quality",
        content:
          "We ensure that our stock has been manufactured using the highest quality materials and machinery so that you can feel secure in knowing that you’re purchasing a product that is reliable and durable",
        icon: qualityIcon,
        is_clicked: false,
      },
      {
        id: 4,
        heading: "We stick to our values",
        content:
          "More than words, our values define who we are and how we operate.  We save you time, effort, and frustration",
        icon: valuesIcon,
        is_clicked: false,
      },
    ],
  };

  handleCardClick = (card) => {
    const data = [...this.state.data];
    const index = data.indexOf(card);
    data[index] = { ...data[index] };
    data[index].is_clicked = !data[index].is_clicked;
    this.setState({ data });
  };

  render() {
    return (
      <Container className="choose-container">
        {/* <h2 className="text-center">Why Us?</h2> */}
        <div
          className="text-center text-justify"
          style={{ fontSize: "15px", color: "black" }}
        >
          AVRN Labs is a product engineering Company providing strategic and
          technology consulting in the field of Internet of Things (IOT), App
          Development and Software and Web Development. We are focused on
          designing and developing customer-centric products and applications
          from start-ups to elite business groups across the globe. We deliver
          the most innovative and agile solutions that enable you to automate,
          inspire and impress.
        </div>
        <Row className="choose-card-row">
          {this.state.data.map((c) => (
            <Col md className="text-center" key={c.id}>
              <ChooseCard
                heading={c.heading}
                content={c.content}
                icon={c.icon}
                is_clicked={c.is_clicked}
                onDownArrowClick={this.handleCardClick}
                card={c}
              />
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

export default ChooseUs;

import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import KnowMoreButton from "./knowMoreButton";
import TextTruncate from "react-text-truncate";

class ModalPopUp extends Component {
  state = {};

  render() {
    const {
      openModal,
      modalTitle,
      modalBody,
      modalImage,
      onModalClose,
      address,
    } = this.props;
    const renderModalBody = () => {
      if (modalImage !== "") {
        return (
          <Row>
            <Col>
              <img
                src={modalImage}
                alt="img"
                width="100%"
                height="100%"
                className="modal--image"
              />
            </Col>
            <Col>
              <div className="text-justify modal--content">
                <h3>{modalTitle}</h3>
                <hr />

                <TextTruncate
                  line={4}
                  element="span"
                  truncateText="..."
                  text={modalBody}
                />
                {address !== "" && address ? (
                  <div className="modal--button">
                    <KnowMoreButton address={address} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row>
            <Col>{modalBody}</Col>
          </Row>
        );
      }
    };
    return (
      <Modal show={openModal} onHide={onModalClose} size="lg" centered>
        {/* <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>{renderModalBody()}</Modal.Body>
        {/* <Modal.Footer>
          <KnowMoreButton address={address} />
        </Modal.Footer> */}
      </Modal>
    );
  }
}

export default ModalPopUp;

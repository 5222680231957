import React, { Component } from "react";
import { FiArrowRight } from "react-icons/fi";

class KnowMoreButton extends Component {
  state = {};
  render() {
    const { address } = this.props;
    return (
      <a href={address} className="btn btn-primary">
        Know More <FiArrowRight />
      </a>
    );
  }
}

export default KnowMoreButton;

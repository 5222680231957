import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FiPhone } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { GoLocation } from "react-icons/go";

class ContactUS extends Component {
  state = {
    address: "E7/MIG 557, Arera Colony, Bhopal",
    phone: "+91 9893611501",
    email: "info@avrn.in",
    data: { name: "", email: "", subject: "", message: "" },
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  render() {
    const { address, phone, email } = this.state;
    return (
      <React.Fragment>
        <div
          className="container-fluid contact-container mt-4"
          id="contactUsHome"
        >
          <h2 className="text-center">Get in touch with us</h2>
          <Row className="contact-row">
            <Col md={6}>
              <div className="map">
                <iframe
                  title="addressMap"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.878739101793!2d77.43402211461357!3d23.21108938485793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c424d795f0809%3A0x2b7b64be879da393!2sAVRN!5e0!3m2!1sen!2sin!4v1578931203446!5m2!1sen!2sin"
                  frameBorder="0"
                  style={{ border: "0", width: "100%", height: "250px" }}
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
            <Col md={6}>
              {/* <Row className="contact-add">
                <Col xs className="text-center">
                  <GoLocation />
                  {address}
                </Col>
                <Col xs className="text-center">
                  <AiOutlineMail />
                  {"  "} {email}
                </Col>
                <Col xs className="text-center">
                  <FiPhone />
                  {phone}
                </Col>
              </Row> */}
              <div style={{ padding: "1%" }}>
                <form onSubmit={this.handleSubmit}>
                  <Row style={{ marginTop: "1%" }}>
                    <Col md>
                      <div className="form-group">
                        <input
                          name="name"
                          id="name"
                          value={this.state.data.name}
                          type="text"
                          placeholder="Your Name"
                          onChange={this.handleChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </Col>
                    <Col md>
                      <div className="form-group">
                        <input
                          name="email"
                          id="email"
                          value={this.state.data.email}
                          type="email"
                          placeholder="Email ID"
                          onChange={this.handleChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="form-group">
                    <input
                      name="subject"
                      id="subject"
                      value={this.state.data.subject}
                      type="subject"
                      placeholder="Subject"
                      onChange={this.handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      value={this.state.data.message}
                      placeholder="Message"
                      onChange={this.handleChange}
                      className="form-control"
                      required
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <input
                      type="submit"
                      className="btn btn-block btn-primary"
                    />
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default ContactUS;

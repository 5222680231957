import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import bannerOne from "../images/bannerOnee.jpg";

class MainCarousel extends Component {
  state = {};
  render() {
    return (
      <div>
        <Carousel className="shadow">
          <Carousel.Item>
            <img
              className="d-block banner-img"
              src={bannerOne}
              alt="First slider"
            />
            <Carousel.Caption>
              <p>
                <span className="firstCaptionWord">Innovate</span>{" "}
                <span className="secondCaptionWord">Future</span>
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          {/* <Carousel.Item>
            <img
              className="d-block banner-img"
              src={bannerTwo}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block banner-img"
              src={bannerThree}
              alt="Third slide"
            />
          </Carousel.Item> */}
        </Carousel>
      </div>
    );
  }
}

export default MainCarousel;

import React, { Component } from "react";
import Banner from "./commons/banner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";

class AllProducts extends Component {
  state = {
    bannerDetails: {
      bannerHeight: "40vh",
      bgImage: null,
      title: "All Products",
      subTitle: "Here are all our products",
      bgColor: "#6593F5",
      textColor: "#ffffff",
    },
    products: [],
  };

  async componentDidMount() {
    const service_id = this.props.match.params.id;
    const res = await axios.post(
      "https://avrn.in/admin/Api_controller/get_service_product",
      {
        service_id: service_id,
      }
    );
    if (res.data.status === 200) {
      const products = res.data.service_products;
      this.setState({ products });
    }
  }

  handleProductClick = (product) => {
    this.props.history.push(`/products/${product.product_id}`);
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <Banner details={this.state.bannerDetails} />
          <div className="allProducts-section">
            <Container>
              <Row>
                {this.state.products.map((product) => (
                  <Col
                    md={4}
                    className="allProducts-col"
                    key={product.product_id}
                    onClick={() => this.handleProductClick(product)}
                  >
                    <div className="product-img-wrap">
                      <img
                        src={product.display_img}
                        width="100%"
                        height="250px"
                        alt="bg"
                        className="product-img"
                      />
                      <h4 className="product-img-text text-center">
                        {product.title}
                      </h4>
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AllProducts;

import React, { Component } from "react";

import { Col, Button, Alert } from "react-bootstrap";

import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

import internPic from "../images/internPicc.jpg";

import "./css/Careers.css";
import Banner from "./commons/banner";
import axios from "axios";
import ImageUploaderCropper from "./commons/imageUploaderCropper";

//Added by Yogesh 01-04-2021
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { scroller } from "react-scroll";

class InternForm extends Component {
  state = {
    data: {
      firstName: "",
      lastName: "",
      aadharNo: "",
      mobile: "",
      branch: "",
      dob: "",
      email: "",
      gender: "",
      addressOne: "",
      addresstwo: "",
      stateName: "",
      city: "",
      pincode: "",
      collegeName: "",
      cv: "",
      sem: "",
      cgpa: "",
      enrollment: "",
      skills: "",
      certifications: "",
      projectLinks: "",
      whyDoYouWantToJoin: "",
      exp: "",
      tpoName: "",
      tpoNumber: "",
      tpoEmail: "",
      hodName: "",
      hodEmail: "",
      hodNumber: "",
      media: "",
    },

    bannerDetails: {
      bannerHeight: "60vh",
      bgImage: internPic,
      title: "",
      subTitle: "",
      bgColor: "#6595F5",
      textColor: "#ffffff",
    },

    isFormSubmitSuccessFull: false,
    isError: false,
    dataFromServer: "",

    errors: {},
  };

  validateData = (data) => {
    let errors = {};
    let isError = false;

    if (data.firstName.length) {
      let regex = new RegExp(/^[a-zA-Z]*$/);
      if (regex.test(data.firstName) === false) {
        errors["firstName"] = "Enter a valid Firstname";
        isError = true;
      }
    }

    if (data.lastName.length) {
      let regex = new RegExp(/^[a-zA-Z]*$/);
      if (regex.test(data.lastName) === false) {
        errors["lastName"] = "Enter a valid Lastname";
        isError = true;
      }
    }

    if (data.mobile.length !== 10 || isNaN(data.mobile)) {
      errors["mobile"] = "Enter a valid Mobile Number";
      isError = true;
    }

    if (new Date(data.dob) > new Date()) {
      errors["dob"] = "Enter a valid DOB";
      isError = true;
    }
    if (data.aadharNo.length !== 12 || isNaN(data.aadharNo)) {
      errors["aadharNo"] = "Enter a valid aadhar number";
      isError = true;
    }
    if (
      data.sem.length !== 1 ||
      new RegExp(/^([1-8])$/).test(data.sem) === false
    ) {
      errors["sem"] = "Enter a valid semester";
      isError = true;
    }
    if (new RegExp(/^[0-9]\.\d$/).test(data.cgpa) === false) {
      errors["cgpa"] = "Enter a valid CGPA";
      isError = true;
    }
    if (data.hodNumber.length !== 10 || isNaN(data.hodNumber)) {
      errors["hodNumber"] = "Enter a valid Hod Number";
      isError = true;
    }

    if (data.tpoNumber.length !== 10 || isNaN(data.tpoNumber)) {
      errors["tpoNumber"] = "Enter a valid tpo Number";
      isError = true;
    }
    if (new RegExp(/^[a-zA-Z ]*$/).test(data.city) === false) {
      errors["city"] = "Enter a valid City";
      isError = true;
    }

    if (new RegExp(/^[a-zA-Z ]*$/).test(data.stateName) === false) {
      errors["stateName"] = "Enter a valid State";
      isError = true;
    }

    if (data.pincode.length !== 6 || isNaN(data.pincode)) {
      errors["pincode"] = "Enter a valid pincode";
      isError = true;
    }
    // validation 31-03-21

    if (Object.keys(errors).length === 0) {
      this.setState({ errors });
      return true;
    } else {
      this.setState({ errors });
      // alert("Please check form");
      toast(errors[Object.keys(errors)[Object.keys(errors).length - 1]]);
      scroller.scrollTo(Object.keys(errors)[Object.keys(errors).length - 1], {
        offset: -200,
        smooth: true,
      });
      window.scrollTo(0, 0);
      return false;
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const data = { ...this.state.data };
    if (this.validateData(data)) {
      this.submitData();
    }
  };

  async submitData() {
    const formData = new FormData();
    const data = { ...this.state.data };
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const res = await axios.post(
      "http://avrn.in/admin/Api_controller/add_intern_form_data",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(res.data);
    if (res.data.status === 200) {
      for (let key in data) {
        data[key] = "";
      }
      const dataFromServer = res.data.formData;
      //console.log("datafromserver", dataFromServer);
      const isFormSubmitSuccessFull = true;
      this.setState({ isFormSubmitSuccessFull, data, dataFromServer });
      window.scrollTo(0, 0);
    } else if (res.data.status === 409) {
      for (let key in data) {
        data[key] = "";
      }
      const isError = true;
      this.setState({ isError, data });
      window.scrollTo(0, 0);
    }
  }

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    data[input.name] = input.value;
    if (input.name in errors) {
      delete errors[input.name];
    }
    this.setState({ data, errors });
  };

  handleFileChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.files[0];
    //console.log(input.files[0]);
    this.setState({ data });
  };

  closeSuccessMessage = () => {
    const isFormSubmitSuccessFull = false;
    const isError = false;
    this.setState({ isFormSubmitSuccessFull, isError });
  };

  render() {
    const {
      firstName,
      lastName,
      collegeName,
      gender,
      mobile,
      email,
      aadharNo,
      branch,
      dob,
      addressOne,
      addresstwo,
      stateName,
      city,
      pincode,
      sem,
      cgpa,
      enrollment,
      skills,
      certifications,
      projectLinks,
      whyDoYouWantToJoin,
      exp,
      tpoName,
      tpoNumber,
      tpoEmail,
      hodName,
      hodEmail,
      hodNumber,
      media,
    } = this.state.data;
    const renderSuccess = () => {
      if (this.state.isFormSubmitSuccessFull) {
        return (
          <Alert
            variant="success"
            onClose={this.closeSuccessMessage}
            dismissible
          >
            <Alert.Heading>Thank You</Alert.Heading>
            <p>Your application has been submitted. </p>
            <p>You will be hearing from us shortly.</p>
            <hr />
            <p className="mb-0">
              In case of any query, please mail to hr@avrn.in
            </p>
          </Alert>
        );
      } else if (this.state.isError) {
        return (
          <Alert
            variant="danger"
            onClose={this.closeSuccessMessage}
            dismissible
          >
            <Alert.Heading>Your application is already submitted</Alert.Heading>
            <p>Your will hear from us shortly.</p>
            <hr />
            <p className="mb-0">
              In Case of any query, please mail to hr@avrn.in
            </p>
          </Alert>
        );
      }
    };
    return (
      <div>
        <Banner details={this.state.bannerDetails} />
        <div className="container" style={{ padding: "5%", paddingTop: "0px" }}>
          {renderSuccess()}
          <div className="form-container">
            <h2 className="text-center">Internship Form</h2>
            <hr />
            <Form onSubmit={this.handleSubmit} name="internForm">
              {/* image cropper and uploader added by yogesh 28-03-2021 */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <ImageUploaderCropper />
              </div>

              <Form.Row>
                <Form.Group as={Col}>
                  <label>First Name*:</label>
                  <input
                    name="firstName"
                    id="name"
                    value={firstName}
                    type="text"
                    placeholder="First Name"
                    onChange={this.handleChange}
                    // className="form-control"
                    className={`form-control ${
                      //added 31-03-21 yogesh
                      !this.state.errors.firstName ? "" : "is-invalid"
                    }`}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <label>Last Name*:</label>
                  <input
                    name="lastName"
                    id="lastName"
                    value={lastName}
                    type="text"
                    placeholder="Last Name"
                    onChange={this.handleChange}
                    // className="form-control"
                    className={`form-control ${
                      //added 31-03-21 yogesh
                      !this.state.errors.lastName ? "" : "is-invalid"
                    }`}
                    required
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Email*:</Form.Label>
                    <InputGroup>
                      <input
                        name="email"
                        id="email"
                        value={email}
                        type="email"
                        placeholder="abc@abc.com"
                        onChange={this.handleChange}
                        className="form-control"
                        required
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group as={Col}>
                    <Form.Label>Mobile*:</Form.Label>
                    <input
                      name="mobile"
                      id="mobile"
                      value={mobile}
                      type="text"
                      placeholder="1234567890"
                      onChange={this.handleChange}
                      className={`form-control ${
                        !this.state.errors.mobile ? "" : "is-invalid"
                      }`}
                      required
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Gender*:</Form.Label>
                    <InputGroup className="mb-2">
                      <select
                        name="gender"
                        id="gender"
                        value={gender}
                        placeholder="Gender"
                        onChange={this.handleChange}
                        className="form-control"
                        required
                      >
                        <option value="">Choose One..</option>
                        <option>Male</option>
                        <option>Female</option>
                      </select>
                    </InputGroup>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group>
                    <Form.Label>Date of Birth*:</Form.Label>
                    <input
                      name="dob"
                      id="dob"
                      value={dob}
                      type="date"
                      placeholder="Date of Birth"
                      onChange={this.handleChange}
                      // className="form-control"
                      className={`form-control ${
                        //added 31-03-21 yogesh
                        !this.state.errors.dob ? "" : "is-invalid"
                      }`}
                      required
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Aadhaar No.*:</Form.Label>
                    <input
                      name="aadharNo"
                      id="aadahrNo"
                      value={aadharNo}
                      type="text"
                      placeholder="Aadhar UUID"
                      onChange={this.handleChange}
                      className={`form-control ${
                        !this.state.errors.aadharNo ? "" : "is-invalid"
                      }`}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Name of college*:</Form.Label>
                    <input
                      name="collegeName"
                      id="collegeName"
                      value={collegeName}
                      type="text"
                      placeholder="Name of College"
                      onChange={this.handleChange}
                      className="form-control"
                      required
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Branch*:</Form.Label>
                    <select
                      name="branch"
                      id="branch"
                      value={branch}
                      placeholder="Branch"
                      onChange={this.handleChange}
                      className="form-control"
                      required
                    >
                      <option value="">Choose One..</option>
                      <option>Mechanical</option>
                      <option>CSE</option>
                      <option>Civil</option>
                      <option>IT</option>
                      <option>EX</option>
                      <option>EC</option>
                      <option>EE</option>
                      <option>EI</option>
                      <option>Others</option>
                    </select>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Semester*:</Form.Label>
                    <input
                      name="sem"
                      id="sem"
                      value={sem}
                      type="text"
                      placeholder="Semester"
                      onChange={this.handleChange}
                      // className="form-control"
                      className={`form-control ${
                        !this.state.errors.sem ? "" : "is-invalid"
                      }`}
                      required
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>CGPA*:</Form.Label>
                    <InputGroup className="mb-2">
                      <input
                        type="text"
                        name="cgpa"
                        id="cgpa"
                        value={cgpa}
                        placeholder="CGPA"
                        onChange={this.handleChange}
                        className={`form-control ${
                          !this.state.errors.cgpa ? "" : "is-invalid"
                        }`}
                        required
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group>
                    <Form.Label>Enrollment*:</Form.Label>
                    <input
                      name="enrollment"
                      id="enrollment"
                      value={enrollment}
                      type="text"
                      placeholder="Enrollment Number"
                      onChange={this.handleChange}
                      className="form-control"
                      required
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>HOD Name*:</Form.Label>
                    <InputGroup className="mb-2">
                      <input
                        type="text"
                        name="hodName"
                        id="hodName"
                        value={hodName}
                        placeholder="Name Of HOD"
                        onChange={this.handleChange}
                        className="form-control"
                        required
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Form.Group>
                    <Form.Label>HOD Email*:</Form.Label>
                    <input
                      name="hodEmail"
                      id="hodEmail"
                      value={hodEmail}
                      type="email"
                      placeholder="Email of HOD"
                      onChange={this.handleChange}
                      className="form-control"
                      required
                    />
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Form.Group>
                    <Form.Label>HOD Number*:</Form.Label>
                    <input
                      name="hodNumber"
                      id="hodNumber"
                      value={hodNumber}
                      type="text"
                      placeholder="Mobile Number of HOD"
                      onChange={this.handleChange}
                      className={`form-control ${
                        !this.state.errors.hodNumber ? "" : "is-invalid"
                      }`}
                      required
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>TPO Name*:</Form.Label>
                    <InputGroup className="mb-2">
                      <input
                        type="text"
                        name="tpoName"
                        id="tpoName"
                        value={tpoName}
                        placeholder="Name Of TPO"
                        onChange={this.handleChange}
                        className="form-control"
                        required
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Form.Group>
                    <Form.Label>TPO Email*:</Form.Label>
                    <input
                      name="tpoEmail"
                      id="tpoEmail"
                      value={tpoEmail}
                      type="email"
                      placeholder="Email of TPO"
                      onChange={this.handleChange}
                      className="form-control"
                      required
                    />
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Form.Group>
                    <Form.Label>TPO Number*:</Form.Label>
                    <input
                      name="tpoNumber"
                      id="tpoNumber"
                      value={tpoNumber}
                      type="text"
                      placeholder="Mobile Number of TPO"
                      onChange={this.handleChange}
                      className={`form-control ${
                        !this.state.errors.tpoNumber ? "" : "is-invalid"
                      }`}
                      required
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Group>
                <Form.Label>Address line 1*:</Form.Label>
                <input
                  name="addressOne"
                  id="addressOne"
                  value={addressOne}
                  type="text"
                  placeholder="Address Line One"
                  onChange={this.handleChange}
                  className="form-control"
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Address line 2*:</Form.Label>
                <input
                  name="addresstwo"
                  id="addresstwo"
                  value={addresstwo}
                  type="text"
                  placeholder="Address Line two"
                  onChange={this.handleChange}
                  className="form-control"
                  required
                />
              </Form.Group>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>City*:</Form.Label>
                  <input
                    name="city"
                    id="city"
                    value={city}
                    type="text"
                    placeholder="City"
                    onChange={this.handleChange}
                    // className="form-control"
                    className={`form-control ${
                      !this.state.errors.city ? "" : "is-invalid"
                    }`}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>State*:</Form.Label>
                  <input
                    name="stateName"
                    id="stateName"
                    value={stateName}
                    type="text"
                    placeholder="State"
                    onChange={this.handleChange}
                    // className="form-control"
                    className={`form-control ${
                      !this.state.errors.stateName ? "" : "is-invalid"
                    }`}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Pincode*:</Form.Label>
                  <input
                    name="pincode"
                    id="pincode"
                    value={pincode}
                    type="text"
                    placeholder="Pin Code"
                    onChange={this.handleChange}
                    className={`form-control ${
                      !this.state.errors.pincode ? "" : "is-invalid"
                    }`}
                    required
                  />
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <Form.Label>Skills*:</Form.Label>
                <textarea
                  name="skills"
                  id="skills"
                  placeholder="Mention Your Skills"
                  rows="5"
                  className="form-control"
                  value={skills}
                  onChange={this.handleChange}
                  required
                ></textarea>
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Certifications(Courses qualified, Ex: NPTEL, RedHat, UpGrad):
                </Form.Label>
                <textarea
                  name="certifications"
                  id="certifications"
                  placeholder="Mention Your Certifications"
                  rows="5"
                  className="form-control"
                  value={certifications}
                  onChange={this.handleChange}
                ></textarea>
              </Form.Group>

              <Form.Group>
                <Form.Label>Project Links:</Form.Label>
                <textarea
                  name="projectLinks"
                  id="projectLinks"
                  placeholder="Github or Google Drive Links of your project"
                  rows="5"
                  className="form-control"
                  value={projectLinks}
                  onChange={this.handleChange}
                ></textarea>
              </Form.Group>

              <Form.Group>
                <Form.Label>Why Do You Want to Join AVRN Labs?*</Form.Label>
                <textarea
                  name="whyDoYouWantToJoin"
                  id="whyDoYouWantToJoin"
                  placeholder="Answer in brief(200 words)"
                  rows="5"
                  className="form-control"
                  value={whyDoYouWantToJoin}
                  onChange={this.handleChange}
                  required
                ></textarea>
              </Form.Group>

              <Form.Group>
                <Form.Label>Previous Experiecne(If Any)</Form.Label>
                <textarea
                  name="exp"
                  id="exp"
                  placeholder="Mention previous internships"
                  rows="5"
                  className="form-control"
                  value={exp}
                  onChange={this.handleChange}
                ></textarea>
              </Form.Group>

              <Form.Group>
                <Form.Label>How you got to know about us?*</Form.Label>
                <input
                  name="media"
                  id="media"
                  value={media}
                  type="text"
                  placeholder="Reference the media"
                  onChange={this.handleChange}
                  className="form-control"
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Upload CV(Should be a pdf, doc or docx file)*:
                </Form.Label>
                <input
                  name="cv"
                  id="cv"
                  type="file"
                  placeholder="CV"
                  onChange={this.handleFileChange}
                  className="form-control-file"
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={false}
          type={"error"}
        />
      </div>
    );
  }
}
export default InternForm;

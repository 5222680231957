import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaFacebook, FaInstagram, FaTwitter, FaGithub } from "react-icons/fa";

class Footer extends Component {
  state = {
    footerLinks: ["Home", "Contact Us"],
    address: "E7/MIG 557, Arera Colony, Bhopal",
    phone: "0755 4075416",
    email: "info@avrn.in",
  };
  render() {
    return (
      <React.Fragment>
        <Container fluid className="footer-container">
          <Container style={{ padding: "1%" }}>
            <Row>
              <Col md={4}>
                <h5 className="font-size-16">AVRN Labs</h5>
                <p className="footer-content">
                  AVRN offers completely packed services that are prebuilt
                  according to specific requirements of clients and help them to
                  define strategy and build their roadmap and provide low cost
                  high technology services.
                </p>
              </Col>
              <Col md={2}>
                <h5 className="font-size-16">Useful Links</h5>
                <div className="footer-links footer-content">
                  {this.state.footerLinks.map((link) => (
                    <p key={link} className="footer-content">
                      {link}
                    </p>
                  ))}
                </div>
              </Col>
              <Col md={3}>
                <h5 className="font-size-16">Contact Us</h5>
                <p>
                  <strong>Address : </strong>
                  <span className="footer-content">{this.state.address}</span>
                </p>
                <p>
                  <strong>Phone : </strong>
                  <span className="footer-content">{this.state.phone}</span>
                </p>
                <p>
                  <strong>Email : </strong>
                  <span className="footer-content">{this.state.email}</span>
                </p>
                <div>
                  <strong>Follow Us</strong>
                  <div>
                    <span className="follow-us-icon">
                      <FaFacebook />
                    </span>
                    <span className="follow-us-icon">
                      <FaInstagram />
                    </span>
                    <span className="follow-us-icon">
                      <FaTwitter />
                    </span>
                    <span className="follow-us-icon">
                      <FaGithub />
                    </span>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <h5 className="font-size-16">Our Newsletter</h5>
                <p className="footer-content">
                  We provide latest news in the industry and as well as variety
                  of our produtcs which can be useful to you or your friends and
                  family. The loads of information on new technology and trends
                  awaits you.
                </p>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className="copy-container">
          <div className="text-center copy-text">
            © Copyright <strong>AVRN Labs</strong> All Rights Reserved
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default Footer;
